<template>
    <div class="grid admintext-demo">
        <Toolbar class="toolbar_top">
            <template v-slot:start>
                <div class="my-2">
                    <h3>{{ t('managingEvaluations') }}</h3>
                    <h3>{{ titleGlobalDocument }}</h3>
                </div>
            </template>
        </Toolbar>
        <div class="col-12">
            <div class="card">
                <div class="my-2" v-if="countStatusImport>0">
                    <h6>{{ t('importSiteDocument') }}</h6>
                </div>
                <DataTable :value="documents" v-model:expandedRows="expandedRowsDoc" :lazy="true"
                           :totalRecords="totalItemsDoc"
                           @page="onPageDoc($event)" dataKey="id" :paginator="true" :rows="nbItems"
                           :rowsPerPageOptions="[5,10,30,50]"
                           :rowHover="true"
                           class="p-datatable-gridlines table-filter-texte"
                           @rowExpand="onRowExpandDoc($event.data.id,1)"
                           v-model:filters="filters1" filterDisplay="menu" :loading="loadingDoc" :filters="filters1"
                           responsiveLayout="scroll"
                           :globalFilterFields="['titleDescription','theme','domain','subDomain', 'rate_progress', 'rate_conformity']"
                           v-model:selection="selectedDocuments"
                           @filter="filterDataTable($event)" :row-class="rowClassDocument">
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #header>
                        <div class="table-header-container">
                            <Button type="button" icon="pi pi-minus" :label="t('cutThroughAll')" @click="collapseAll"
                                    class="add-text p-button-help mr-2"/>
                            <Button label="Export" icon="pi pi-download" class="add-text  mr-2"
                                    @click="exportDocuments"/>
                            <Button label="Import" icon="pi pi-upload" class="add-text  mr-2" @click="importdocuments"
                                    v-if="!['1','3','2'].includes(role_user_site)"/>
                        </div>
                    </template>
                    <Column :expander="true" headerStyle="width: 1rem" style="width: 1rem;padding: 2px !important;"
                            class="toggler_chevron"/>
                    <Column selectionMode="multiple"
                            style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>
                    <template #empty>
                        {{ t('NoDataToDisplay') }}
                    </template>
                    <Column header="" class="td_flag">
                        <template #body="slotProps">
                            <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="20"
                                 v-if="slotProps?.data?.live_status?.color==='black'"/>
                            <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="20"
                                 v-if="slotProps?.data?.live_status?.color==='red'"/>
                            <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="20"
                                 v-if="slotProps?.data?.live_status?.color==='yellow'"/>
                            <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="20"
                                 v-if="slotProps?.data?.live_status?.color==='green'"/>
                            <img :src="'demo/images/flags/warning.png'" :alt="slotProps.data.image" width="20"
                                 class="warning"
                                 v-if="warningText(slotProps.data?.min_evaluation_date) === 'red'"/>
                            <img :src="'demo/images/flags/warning-orange.png'" :alt="slotProps.data.image" width="20"
                                 class="warning"
                                 v-else-if="warningText(slotProps.data?.min_evaluation_date) === 'orange'"/>
                        </template>
                    </Column>
                    <Column field="name" :header="t('text')" style="width:10rem" filterField="titleDescription"
                            :showFilterMatchModes="false">
                        <template #body="{data}">
                            <div class="tooltip"
                                 v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p><p><strong>Description :</strong> '+
                                 replaceAllColorsWithWhite(data.description)+'</p><p><strong>'+t('fieldApplication') +': </strong>'+data.application_fields+'</p>'">
                                <p>{{ data.title }}</p>
                                <p v-html="data.description" v-if="data.description !== 'null'" class="text-light"></p>
                            </div>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                       :placeholder="t('search')"/>
                        </template>
                    </Column>
                    <Column :header="t('theme')" style="min-width:8rem" filterField="theme"
                            :showFilterMatchModes="false">
                        <template #body="{data}">
              <span style="vertical-align: middle" v-if="data.theme_id"
                    class="image-text">{{ data.theme_id }}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <MultiSelect v-model="filterModel.value" :options="categoriesTheme" optionLabel="name"
                                         :placeholder="t('theme')"
                                         class="w-full md:w-20rem"/>

                            <!--                            <Dropdown v-model="filterModel.value" :options="categoriesTheme" placeholder="Any"-->
                            <!--                                      class="p-column-filter"-->
                            <!--                                      :showClear="true">-->
                            <!--                                <template #value="slotProps">-->
                            <!--                  <span :class="'customer-badge status-' + slotProps.value"-->
                            <!--                        v-if="slotProps.value">{{ slotProps.value.label }}</span>-->
                            <!--                                    <span v-else>{{ t('select') }}</span>-->
                            <!--                                </template>-->
                            <!--                                <template #option="slotProps">-->
                            <!--                                    <span :class="'customer-badge status-' + slotProps.option.label">{{-->
                            <!--                                        slotProps.option.label-->
                            <!--                                        }}</span>-->
                            <!--                                </template>-->
                            <!--                            </Dropdown>-->
                        </template>
                    </Column>
                    <Column :header="t('domain')" filterField="domain" :showFilterMatchModes="false"
                            :filterMenuStyle="{'width':'14rem'}" style="min-width:8rem">
                        <template #body="{data}">
              <span style="vertical-align: middle" class="image-text" v-if="data.domain_id">{{
                  data.domain_id
                  }}</span>
                        </template>
                        <template #filter="{filterModel}">

                            <MultiSelect v-model="filterModel.value" :options="categoriesDomain" optionLabel="name"
                                         :placeholder="t('domain')"
                                         class="w-full md:w-20rem"/>

                            <!--                            <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder="Any"-->
                            <!--                                      class="p-column-filter" :showClear="true">-->
                            <!--                                <template #value="slotProps">-->
                            <!--                  <span :class="'customer-badge status-' + slotProps.value"-->
                            <!--                        v-if="slotProps.value">{{ slotProps.value.label }}</span>-->
                            <!--                                    <span v-else>{{ t('select') }}</span>-->
                            <!--                                </template>-->
                            <!--                                <template #option="slotProps">-->
                            <!--                                    <span :class="'customer-badge status-' + slotProps.option.label">{{-->
                            <!--                                        slotProps.option.label-->
                            <!--                                        }}</span>-->
                            <!--                                </template>-->
                            <!--                            </Dropdown>-->
                        </template>
                    </Column>
                    <Column :header="t('subDomain')" filterField="subDomain" :showFilterMatchModes="false"
                            style="min-width:8rem">
                        <template #body="{data}">
               <span style=" vertical-align: middle" class="image-text" v-if="data.sub_domain_id">

                     {{ data.sub_domain_id }}
               </span>
                        </template>
                        <template #filter="{filterModel}">

                            <MultiSelect v-model="filterModel.value" :options="categoriesSubDomain" optionLabel="name"
                                         :placeholder="t('subDomain')"
                                         class="w-full md:w-20rem"/>

                            <!--                            <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder="Any"-->
                            <!--                                      class="p-column-filter" :showClear="true">-->
                            <!--                                <template #value="slotProps">-->
                            <!--                  <span :class="'customer-badge status-' + slotProps.value"-->
                            <!--                        v-if="slotProps.value">{{ slotProps.value.label }}</span>-->
                            <!--                                    <span v-else>{{ t('select') }}</span>-->
                            <!--                                </template>-->
                            <!--                                <template #option="slotProps">-->
                            <!--                                    <span :class="'customer-badge status-' + slotProps.option.label">{{-->
                            <!--                                        slotProps.option.label-->
                            <!--                                        }}</span>-->
                            <!--                                </template>-->
                            <!--                            </Dropdown>-->
                        </template>
                    </Column>
                    <Column :header="t('progressRate')" filterField="rate_progress" dataType="numeric"
                            style="min-width:7rem"
                            class="progressBarValue" :showFilterMatchModes="false">
                        <template #body="slotProps">
                            <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                                           v-if="slotProps.data.progression<=50 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                           :showValue="true" style="height:20px" class="valuered"></AxProgressBar>
                            <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                                           v-if="slotProps.data.progression>50 && slotProps.data.progression<70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                           :showValue="true"
                                           style="height:20px" class="valueyellow"></AxProgressBar>
                            <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                                           v-if="slotProps.data.progression>=70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                           :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
                        </template>

                        <template #filter={filterModel}>
                            <Slider v-model="filterModel.value" range class="m-3"></Slider>
                            <div class="flex align-items-center justify-content-between px-2">
                                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
                                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column :header="t('complianceRate')" filterField="rate_conformity" dataType="numeric"
                            style="min-width:7rem"
                            :showFilterMatchModes="false">
                        <template #body="slotProps">
                            <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                                           v-if="slotProps.data.conformity<=50 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                           :showValue="true" style="height:20px" class="valuered"></AxProgressBar>
                            <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                                           v-if="slotProps.data.conformity>50 && slotProps.data.conformity<70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                           :showValue="true"
                                           style="height:20px" class="valueyellow"></AxProgressBar>
                            <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                                           v-if="slotProps.data.conformity>=70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                           :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
                        </template>
                        <template #filter={filterModel}>
                            <Slider v-model="filterModel.value" range class="m-3"></Slider>
                            <div class="flex align-items-center justify-content-between px-2">
                                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
                                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column :header="t('supportingDocuments')" style="min-width:9rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="btn_file_pdf button-table mr-2"
                                    @click="attachFile(slotProps.data.summary_file_id)"
                                    v-tooltip.top="{value:t('synthesis'), class: 'editBtn'}"
                                    :disabled="!slotProps.data.summary_file_id"/>
                            <Button icon="pi pi-file" class="btn_file button-table mr-2"
                                    @click="attachFile(slotProps.data.illustrations_file_id)"
                                    v-tooltip.top="{value:'Document', class: 'editBtn'}"
                                    :disabled="!slotProps.data.illustrations_file_id"/>
                            <a :href="slotProps.data.url" target="_blank">
                                <Button icon="pi pi-link" class="btn_link button-table mr-2"
                                        v-tooltip.top="{value:t('link'), class: 'editBtn'}"/>
                            </a>
                            <Button icon="pi pi-align-justify" class="button-table mr-2"
                                    @click="editDocument(slotProps.data)"
                                    v-tooltip.top="{value:t('textEvaluation'), class: 'editBtn'}" disabled/>

                            <Button icon="pi pi-sort-alt" class="btn_comment button-table mr-2"
                                    @click="openModalTransversalEvaluations(slotProps.data)"
                                    v-tooltip.top="{value:t('transversalAssessment'), class: 'editBtn'}"/>
                            <Button icon="pi pi-fw pi-comment" class="btn_comment button-table mr-2"
                                    @click="openDiscussion(slotProps.data)"
                                    v-tooltip.top="{value: 'Hotline', class: 'editBtn'}"
                            />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="orders-subtable eval_level_1">
                            <DataTable :value="slotProps.data.sections" responsiveLayout="scroll"
                                       :lazy="true"
                                       v-model:expandedRows="expandedRowsSec"
                                       @rowExpand="onRowExpandSection($event.data, 1)"
                                       :loading="loadingSection[slotProps.data.id]"
                                       :paginator="true" :rows="10" :row-class="rowClass"
                                       :totalRecords="totalItemsSect[slotProps.data.id]"
                                       @page="onPageSec(slotProps.data.id, $event.page+1)">
                                <Column :expander="true" headerStyle="width: 3rem">
                                </Column>
                                <template #empty>
                                    {{ t('NoDataToDisplay') }}
                                </template>
                                <Column header="" class="td_flag" style="width:35px">
                                    <template #body="slotProps">

                                        <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image"
                                             width="20"
                                             v-if="differenceDate(slotProps.data.last_update_compliance)"/>

                                        <img :src="'demo/images/flags/warning.png'" :alt="slotProps.data.image"
                                             width="20" class="warning"
                                             v-if="warningText(slotProps.data?.min_evaluation_date) === 'red'"/>
                                        <img :src="'demo/images/flags/warning-orange.png'" :alt="slotProps.data.image"
                                             width="20"
                                             class="warning"
                                             v-else-if="warningText(slotProps.data?.min_evaluation_date) === 'orange'"/>
                                    </template>
                                </Column>
                                <Column field="text" style="width:50rem">
                                    <template #body="slotProp">
                                        {{ truncate(slotProp.data.title) }}
                                    </template>
                                </Column>


                                <Column field="conformity" style="width:20rem">>
                                    <template #body="slotProps">
                                        <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                                                       v-if="slotProps.data.progression<=50 && slotProps.data.compliances_count>0 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                                       :showValue="true" style="height:20px"
                                                       class="valuered"></AxProgressBar>
                                        <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                                                       v-if="slotProps.data.progression>50 && slotProps.data.progression<70 && slotProps.data.compliances_count>0 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                                       :showValue="true"
                                                       style="height:20px" class="valueyellow"></AxProgressBar>
                                        <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                                                       v-if="slotProps.data.progression>=70 && slotProps.data.compliances_count>0 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                                       :showValue="true" style="height:20px"
                                                       class="valuegreen"></AxProgressBar>
                                    </template>
                                </Column>
                                <Column field="progression" style="width:20rem">>
                                    <template #body="slotProps">
                                        <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                                                       v-if="slotProps.data.conformity<=50 && slotProps.data.compliances_count>0 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                                       :showValue="true" style="height:20px"
                                                       class="valuered"></AxProgressBar>
                                        <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                                                       v-if="slotProps.data.conformity>50 && slotProps.data.conformity<70 && slotProps.data.compliances_count>0 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                                       :showValue="true"
                                                       style="height:20px" class="valueyellow"></AxProgressBar>
                                        <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                                                       v-if="slotProps.data.conformity>=70 && slotProps.data.compliances_count>0 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count)"
                                                       :showValue="true" style="height:20px"
                                                       class="valuegreen"></AxProgressBar>
                                    </template>
                                </Column>
                                <template #expansion="slotProps">
                                    <div class="orders-subtable eval_level_2">
                                        <h5>{{ slotProps.data.name }}</h5>
                                        <DataTable :value="slotProps.data.compliances" responsiveLayout="scroll"
                                                   :lazy="true" :totalRecords="totalItemsCompliance[slotProps.data.id]"
                                                   @page="onpageCompliance(slotProps.data, $event)"
                                                   :loading="loadingCompliance[slotProps.data.id]" :paginator="true"
                                                   :rows="10">
                                            <template #empty>
                                                {{ t('NoDataToDisplay') }}
                                            </template>
                                            <Column header="" class="td_flag" style="width:35px">
                                                <template #body="slotProps">
                                                    <img :src="'demo/images/flags/flag-black.png'"
                                                         :alt="slotProps.data.image" width="20"
                                                         v-if="slotProps?.data?.live_status?.color==='black'"/>
                                                    <img :src="'demo/images/flags/flag-red.png'"
                                                         :alt="slotProps.data.image" width="20"
                                                         v-if="slotProps?.data?.live_status?.color==='red'"/>
                                                    <img :src="'demo/images/flags/flag-yellow.png'"
                                                         :alt="slotProps.data.image" width="20"
                                                         v-if="slotProps?.data?.live_status?.color==='yellow'"/>
                                                    <img :src="'demo/images/flags/flag-green.png'"
                                                         :alt="slotProps.data.image" width="20"
                                                         v-if="slotProps?.data?.live_status?.color==='green'"/>
                                                    <img :src="'demo/images/flags/warning.png'"
                                                         :alt="slotProps.data.image" width="20" class="warning"
                                                         v-if="warningText(slotProps.data?.evaluations[0].evaluation_date) === 'red'"/>
                                                    <img :src="'demo/images/flags/warning-orange.png'"
                                                         :alt="slotProps.data.image" width="20"
                                                         class="warning"
                                                         v-else-if="warningText(slotProps.data?.evaluations[0].evaluation_date) === 'orange'"/>
                                                </template>
                                            </Column>
                                            <Column field="text" :header="t('titleOfCompliance')" style="width:10rem">
                                                <template #body="slotProps">
                                                    <div class="tooltip"
                                                         v-tooltip.right="'<p>' +
                                (slotProps.data.annotation_compliance ? '<strong>'+t('novalliaAnnotation')+ ': </strong>' + slotProps.data.annotation_compliance  : '') +
                                '</p><p>' +
                                (slotProps.data.evaluations[0].annotation ?  '<strong>'+ t('customerAnnotation')+ ' :</strong> '+ slotProps.data.evaluations[0].annotation : '' )+
                                 ' </p><p>' +
                                 (slotProps.data.evaluations[0].comment ? '<strong>'+ t('comment')+' :</strong>'+ slotProps.data.evaluations[0].comment : '' )+
                                '</p><p>' +
                                 (slotProps.data.evaluations[0].evaluation_date ? '<strong>'+ t('valuationDate') + ' :</strong>'+  formatDate(slotProps.data.evaluations[0].evaluation_date) : '')  +
                                 '</p>'">
                                                        <p v-if="isRte">{{ truncateText(slotProps.data.title_rte, 60) }}</p>
                                                        <p v-else>{{ truncateText(slotProps.data.titleSection, 60) }}</p>
                                                    </div>
                                                </template>
                                            </Column>
                                            <Column field="text" :header="t('textOfCompliance')" style="width:50rem">
                                                <template #body="slotProps">
                                                    <div class="tooltip"
                                                         v-tooltip.top="'<p>' +

                          (slotProps.data.annotation_compliance ? '<strong>'+t('novalliaAnnotation')+ ': </strong>' + slotProps.data.annotation_compliance  : '') +
                          '</p><p>' +
                          (slotProps.data.evaluations[0].annotation ?  '<strong>'+ t('customerAnnotation')+ ' :</strong> '+ slotProps.data.evaluations[0].annotation : '' )+
                           ' </p><p>' +
                           (slotProps.data.evaluations[0].comment ? '<strong>'+ t('comment')+' :</strong>'+ slotProps.data.evaluations[0].comment : '' )+
                          '</p><p>' +
                           (slotProps.data.evaluations[0].evaluation_date ? '<strong>'+ t('valuationDate') + ' :</strong>'+  formatDate(slotProps.data.evaluations[0].evaluation_date) : '')  +
                           '</p>'">
                                                        <span v-html="slotProps.data.title.replace(/^<p>/i, '')"> </span>
                                                    </div>
                                                </template>
                                            </Column>
                                            <Column field="text" :header="t('evaluation')" style="width:10rem">
                                                <template #body="slotProps">
                          <span style=" vertical-align: middle" class="image-text"
                                v-if="slotProps.data.evaluations[0]">
                              <span v-if="slotProps.data.evaluations[0].status">
                               {{ slotProps.data.evaluations[0].status.name }}
                                </span>
                    </span>
                                                </template>
                                            </Column>
                                            <Column header="" style="width:10rem; text-align: center;">
                                                <template #body="slotProps">
                                                    <Button icon="pi pi-sync" class="button-table mr-2"
                                                            @click="dialogEvaluation(slotProps.data)"/>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
                <Dialog v-model:visible="evaluationDialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle">
                    <template #header>
                        <div class="flex align-items-center">
                            <img :src="'demo/images/flags/warning.png'" width="20" class="warning"
                                 v-if="evaluation?.live_status?.color"/>

                            <span v-html="titleDialogEvaluation" style="padding-left: 20px;"></span>
                        </div>

                    </template>
                    <TabView class="tabs_texte_admin tabview-custom">
                        <TabPanel>
                            <template #header>
                                <span>{{ t('evaluations') }}</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('evaluation') }}<span>*</span> </label>
                                    <Dropdown v-model="evaluationStatus" :options="valuesEvaluations" optionLabel="name"
                                              required="true"
                                              :class="{'p-invalid': submittedEval && !evaluationStatus}"
                                              @change="chnageStatus()"/>
                                    <small class="p-invalid" v-if="submittedEval && !evaluationStatus">Evaluation is
                                        required.</small>
                                </div>
                                <div class="field-control col">
                                    <label for="titre">{{ t('valuationDate') }} :</label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="evaluation_date"
                                              dateFormat="dd/mm/yy"></Calendar>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col" v-if="!verifAdmin">
                                    <label for="description">Annotation :</label>
                                    <Textarea id="description" v-model="evaluation.annotation" required="true" rows="5"
                                              cols="20"
                                              disabled/>
                                </div>
                                <div class="field-control col" v-if="verifAdmin">
                                    <label for="description">Annotation :</label>
                                    <Textarea id="description" v-model="evaluation.annotation" required="true" rows="5"
                                              cols="20"/>
                                </div>
                                <div class="field-control col">
                                    <label for="description">{{ t('comment') }} :</label>
                                    <Textarea id="description" v-model="evaluation.comment" required="true" rows="5"
                                              cols="20"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="description">Annotation Novallia:</label>
                                    <p>{{ truncateText(evaluation.annotation_compliance, 100) }}</p>
                                    <!--                  <Textarea id="description" v-model="evaluation.annotation_compliance" required="true" rows="2" cols="10" disabled/>-->
                                </div>
                                <div class="field-control col">
                                </div>
                            </div>
                            <!--              <div class="formgrid grid">-->

                            <!--                <div class="field-radiobutton pt-5">-->
                            <!--                  <Checkbox id="checkOption1" name="option" value="New York" v-model="evaluation.is_confidential"/>-->
                            <!--                  <label for="confidentiel">Créer un plan d'actions sommaire (le Commentaire deviendra le libellé du-->
                            <!--                    PA)</label>-->
                            <!--                </div>-->
                            <!--              </div>-->

                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('historic') }}</span>
                            </template>
                            <DataTable :value="historicEvaluation" :paginator="true" :rows="10" :lazy="true"
                                       :totalRecords="totalItemsHistEval"
                                       responsiveLayout="scroll" @page="onPageHistEval($event.page +1)"
                                       class="p-datatable-gridlines table-filter-texte">

                                <template #empty>
                                    {{ t('NoDataToDisplay') }}
                                </template>

                                <Column field="evaluation" header="Evaluation">
                                    <template #body="slotProps">
                                        {{ slotProps.data.status }}
                                    </template>
                                </Column>
                                <Column field="ValuedBy" :header="t('ValuedBy')">
                                    <template #body="slotProps">
                                        {{ slotProps.data.username }}
                                    </template>
                                </Column>
                                <Column field="representative.name" :header="t('comment')">
                                    <template #body="slotProps">
                                        {{ slotProps.data.comment }}
                                    </template>
                                </Column>
                                <Column field="representative.name" :header="t('valuationDate')">
                                    <template #body="slotProps">
                                        {{ formatDate(slotProps.data.evaluation_date) }}
                                    </template>
                                </Column>
                                <Column field="representative.name" :header="t('updatedAt')">
                                    <template #body="slotProps">
                                        {{ formatDate(slotProps.data.updated_at) }}
                                    </template>
                                </Column>

                            </DataTable>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('actionPlan') }}</span>
                            </template>
                            <DataTable :value="actionPlanList" :paginator="true" :rows="30" :lazy="true"
                                       :totalRecords="totalItemsAP"
                                       responsiveLayout="scroll" @page="onPageActionPlan($event.page +1)"
                                       class="p-datatable-gridlines table-filter-texte" :loading="loadingAPList">
                                <template #empty>
                                    {{ t('NoDataToDisplay') }}
                                </template>
                                <template #header>
                                    <div class="table-header-container" style="display:flex">
                                        <Button :label="t('attachActionPlan')" icon="pi pi-paperclip"
                                                class="p-button p-component add-text mr-2"
                                                @click="modalAttachAP"/>
                                        <Button :label="t('addActionPlan')" icon="pi pi-plus"
                                                class="p-button p-component add-text mr-2"
                                                @click="openModalAP"/>
                                    </div>
                                </template>
                                <Column field="ValuedBy" :header="t('title')">
                                    <template #body="slotProps">
                                        {{ slotProps.data.title }}
                                    </template>
                                </Column>
                                <Column style="min-width:4rem">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                                                @click="modalEditPAEval(slotProps.data)"
                                                v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                                        <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                                                @click="deleteAPEval(slotProps.data)"
                                                v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                                                v-if="!['1','3'].includes(role_user_site)"/>
                                    </template>
                                </Column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel>
                        <template #header>
                          <span>Documents</span>
                        </template>
                        <div class="formgrid grid">
                          <div class="field-control col-12">
                            <div class="flex btn_download"
                                 :style="{ 'pointer-events': evaluation_file ?  'auto': 'none' , opacity: evaluation_file ?  1 : 0}">
                              <Button :label="evaluation_file?.name" class="p-button-text event_remove"
                                      @click="attachFile(evaluation_file?.id, 'evaluation')"/>
                              <Button icon="pi pi-times" class="p-button-text event_remove"
                                      @click="deleteFile(evaluation_file?.id, 'summary')"/>
                            </div>
                            <div class="flex">
                              <FileUpload mode="basic" ref="fileEvaluation" label="Import"
                                          chooseLabel="Import" class="mr-2 inline-block"
                                          v-on:change="onUploadFileEvaluation()"/>
                              <Button :label="t('Téléverser')" icon="pi pi-check" class="primary-btn" :loading="btnEvaluationFile"
                                      v-if="!['1','3','2'].includes(role_user_site)"  @click="UploadFileEvaluation" :disabled="btnEvaluationFile"/>
                            </div>



                          </div>
                          <div class="field-control col-12">

                          </div>

                        </div>
                      </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialog"/>

                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="saveEvaluation" :disabled="btnEvaluationSubmit"
                                v-if="!['1','3','2'].includes(role_user_site)"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="importDocumentDialog" :style="{width: '900px'}" :header="t('importCompliance')"
                        :modal="true"
                        class="modal_general p-fluid">

                    <!--          <TabView class="tabs_texte_admin tabview-custom">-->
                    <!--            <TabPanel>-->
                    <!--              <template #header>-->
                    <!--                <span>Documents</span>-->
                    <!--              </template>-->

                    <div class="formgrid grid">
                        <div class="field-control col-12">
                            <label for="uploadfiles">{{ t('complianceFile') }}:</label>

                            <FileUpload mode="basic" accept=".xlsx" ref="file" label="Import"
                                        chooseLabel="Import" class="mr-2 inline-block"
                                        v-on:change="onUploadDocuments()"/>


                            <!--                <input type="file" id="file" ref="file" v-on:change="onUploadSites()"/>-->

                        </div>


                    </div>


                    <!--            </TabPanel>-->

                    <!--          </TabView>-->
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogImportSites"/>


                        <Button :label="t('import')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="importDocument()"/>

                    </template>

                </Dialog>
                <!--        Hotline-->
                <Dialog v-model:visible="isHotline" :style="{width: '900px'}" :header="hotlineTitle"
                        :modal="true"
                        class="modal_general p-fluid">

                    <div class="formgrid grid">
                        <div class="field-control col-12">
                            <div>
                                <Editor v-model="textAreaValue" editorStyle="height: 160px"
                                        :placeholder="t('yourMessage')">
                                    <template #toolbar>
              <span class="ql-formats">
                        <select class="ql-header"></select>
                        <select class="ql-font"></select>
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
              </span>
                                        <span class="ql-formats">
                 <button class="ql-list" value="ordered"></button>
                 <button class="ql-list" value="bullet"></button>
                 <select class="ql-align"></select>
              </span>
                                        <span class="ql-formats">
                 <button class="ql-link"></button>
                 <button class="ql-image"></button>
                 <button class="ql-code-block"></button>
              </span>
                                        <span class="ql-formats">
                  <button class="ql-clean"></button>
              </span>
                                    </template>
                                </Editor>
                                <br/>
                                <br/>
                                <FileUpload ref="fileUploadDiscussion" name="fileArray[]" :multiple="true"
                                            :chooseLabel="'Importer des fichiers'" :cancelLabel="' '"
                                            :showUploadButton="false"/>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideOpenDiscussion"/>

                        <Button :disabled="textAreaValue ===  '' || sendClicked" :label="t('send')" icon="pi pi-check"
                                class="p-button-text next_btn"
                                @click="sendDiscussion()"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="PADialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
                    <div class="formgrid grid">
                        <div class="field-control col">

                            <label for="titre">{{ t('title') }}<span>*</span> </label>
                            <InputText id="name" v-model="planAction.title" autofocus
                                       :class="{'p-invalid': submittedPA && !planAction.title}"/>
                            <small class="p-invalid" v-if="submittedPA && !planAction.title">{{
                                t('titleRequired')
                                }}</small>
                        </div>
                        <div class="field-control col">
                            <label for="titre">{{ t('reference') }} </label>
                            <InputText id="name" v-model="planAction.reference" autofocus/>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="statut">{{ t('subDomain') }}</label>
                            <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                                        placeholder="Select Item"
                                        @node-select="selectNode"/>
                        </div>
                        <div class="field-control col">
                            <label for="Priorite">{{ t('priority') }} </label>
                            <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name" required="true"
                                      @change="chnageStatus($event)"/>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="Priorite">{{ t('apPilot') }} </label>
                            <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"
                                      @change="chnageStatus($event)"/>
                        </div>

                        <div class="field-control col">
                            <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
                            <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                                      required="true"
                                      :class="{'p-invalid': submittedPA && !responsibleAP}"/>
                            <small class="p-invalid" v-if="submittedPA && !responsibleAP">{{
                                t('responsibleAPRequired')
                                }}</small>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="Priorite">{{ t('typeProgresion') }} </label>
                            <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                                      required="true"/>
                        </div>

                        <div class="field-control col">
                            <label for="Priorite">{{ t('rateProgress') }} </label>
                            <Dropdown v-model="rateProgress" :options="listRateProgression" optionLabel="label"/>
                        </div>

                    </div>
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="titre">{{ t('startDate') }} <span>*</span></label>
                            <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                                      dateFormat="dd/mm/yy"
                                      :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
                            <small class="p-invalid" v-if="submittedPA && !start_date">{{
                                t('startDateRequired')
                                }}</small>
                        </div>

                        <div class="field-control col">
                            <label for="titre">{{ t('endDate') }} <span>*</span></label>
                            <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                                      dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                                      @update:modelValue="changeEndDate($event)"></Calendar>
                            <small class="p-invalid" v-if="submittedPA && !end_date">{{ t('endDateRequired') }}</small>
                        </div>

                        <div class="field-control col">
                            <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
                            <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                                      dateFormat="dd/mm/yy"
                                      :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
                            <small class="p-invalid"
                                   v-if="submittedPA && !revised_end_date">{{ t('revisedEndDateRequired') }}</small>
                        </div>
                    </div>


                    <div class="formgrid grid">
                        <div class="field-control col">
                            <div class="field-radiobutton pt-4">
                                <Checkbox id="checkOption1" name="option" :binary="true"
                                          v-model="planAction.reminder_fixed"/>
                                <label for="confidentiel">{{ t('fixedReminder') }}</label>
                            </div>
                        </div>

                        <div class="field-control col">
                            <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
                            <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus/>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field-control col">
                            <div class="field-radiobutton pt-4">
                                <Checkbox id="checkOption1" name="option" :binary="true"
                                          v-model="planAction.reminder_periodic"/>
                                <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
                            </div>
                        </div>

                        <div class="field-control col">
                            <label for="titre">{{ t('periodicReminderTime') }} </label>
                            <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus/>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="description">{{ t('divers') }} </label>
                            <Textarea id="description" v-model="planAction.others" required="true" rows="5" cols="20"/>
                        </div>
                    </div>

                    <template #footer>

                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogPA"/>


                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="savePA()" :disabled="btnAPSubmit" v-if="!['1','3'].includes(role_user_site)"/>

                    </template>

                </Dialog>
                <Dialog v-model:visible="PAEvalEditDialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" :header="titleAP">
                    <TabView class="tabs_texte_admin tabview-custom">
                        <TabPanel>
                            <template #header>
                                <span>{{ t('compliances') }}</span>
                            </template>
                            <DataTable :value="evaluationsAP" :totalRecords="totalItemsCompliancePA"
                                       dataKey="id" :paginator="true" :rows="30"
                                       :rowHover="true"
                                       class="p-datatable-gridlines table-filter-texte"
                                       :loading="loadingCompliancesPA"
                                       responsiveLayout="scroll"
                                       @page="onPageEvaluation($event.page+1)">
                                <template #loading>
                                    Loading data. Please wait.
                                </template>
                                <template #empty>
                                    {{ t('NoDataToDisplay') }}
                                </template>
                                <template #header>
                                    <div class="table-header-container">
                                        <Button :label="t('addACompliance')" icon="pi pi-plus"
                                                class="add-text p-button-success mr-2"
                                                @click="openModalCompliance()"/>
                                    </div>
                                </template>
                                <Column field="name" :header="t('titleOfText')" filterField="title"
                                        :showFilterMatchModes="false">
                                    <template #body="{data}">
                                        <div class="tooltip"
                                             v-tooltip.right="'<p><strong>'+t('titleOfText')+ ':</strong>'+ data?.compliance?.section?.document?.title+'</p>'">
                                            <p v-html="data?.compliance?.section?.document?.title"></p>
                                        </div>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('titleOfCompliance')" filterField="title"
                                        :showFilterMatchModes="false">
                                    <template #body="{data}">
                                        <div class="tooltip"
                                             v-tooltip.right="'<p><strong>'+t('titleOfCompliance')+ ':</strong>'+ data?.compliance?.section?.title+'</p>'">
                                            <p v-html="data?.compliance?.section?.title"></p>
                                        </div>
                                    </template>
                                </Column>
                                <Column field="name" :header="t('text')" filterField="title"
                                        :showFilterMatchModes="false">
                                    <template #body="{data}">
                                        <div class="tooltip"
                                             v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data?.compliance?.title+'</p>'">
                                            <p v-html="data?.compliance?.title"></p>
                                        </div>
                                    </template>
                                </Column>
                                <Column style="min-width:4rem">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                                                @click="dialogEvaluation(slotProps.data, 'isInAP')"
                                                v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                                        <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                                                @click="deleteCompliance(slotProps.data)"
                                                v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                                                v-if="!['1','3','2'].includes(role_user_site)"/>
                                    </template>
                                </Column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('tasks') }}</span>
                            </template>

                            <DataTable :value="actionPlanDetails.action_plan_tasks"
                                       :totalRecords="totalItemsTasks"
                                       dataKey="id" :paginator="true" :rows="30"
                                       :rowHover="true"
                                       class="p-datatable-gridlines table-filter-texte"
                                       :loading="loadingTasks"
                                       responsiveLayout="scroll">
                                <template #loading>
                                    Loading data. Please wait.
                                </template>

                                <template #empty>
                                    {{ t('NoDataToDisplay') }}
                                </template>
                                <template #header>
                                    <div class="table-header-container">
                                        <Button :label="t('addingTasks')" icon="pi pi-plus"
                                                class="add-text p-button-success mr-2"
                                                @click="openModalTasks()"/>
                                    </div>
                                </template>

                                <Column field="name" :header="t('wording')" style="width:10rem">
                                    <template #body="{data}">
                                        <div class="tooltip"
                                             v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p>'">
                                            <p>{{ data.title }}</p>
                                        </div>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('responsibleTasks')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ data?.user_in_charge?.firstname }} {{
                                            data?.user_in_charge?.lastname
                                            }}</p>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('startDate')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ formatDate(data.start_date) }}</p>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('endDate')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ formatDate(data.end_date) }}</p>
                                    </template>
                                </Column>
                                <Column :header="t('progressRate')" filterField="rate_progress" dataType="numeric"
                                        style="min-width:7rem"
                                        class="progressBarValue">
                                    <template #body="slotProps">
                                        <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                                                       v-if="slotProps.data.rate_progress<=50"
                                                       :showValue="true" style="height:20px"
                                                       class="valuered"></AxProgressBar>
                                        <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                                                       v-if="slotProps.data.rate_progress>50 && slotProps.data.rate_progress<70"
                                                       :showValue="true"
                                                       style="height:20px" class="valueyellow"></AxProgressBar>
                                        <AxProgressBar :value="Number(slotProps.data.rate_progress).toFixed(1)"
                                                       v-if="slotProps.data.rate_progress>=70 "
                                                       :showValue="true" style="height:20px"
                                                       class="valuegreen"></AxProgressBar>
                                    </template>
                                </Column>

                                <Column style="min-width:4rem">
                                    <template #body="slotProps">

                                        <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                                                @click="openModalTasks(slotProps.data)"
                                                v-tooltip.top="{value:t('edit'), class: 'editBtn'}"
                                        />
                                        <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                                                @click="deleteTask(slotProps.data)"
                                                v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                                                v-if="!['1','3'].includes(role_user_site)"/>
                                    </template>
                                </Column>

                            </DataTable>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>Identification</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="titre">{{ t('title') }}<span>*</span> </label>
                                    <InputText id="name" v-model="planAction.title" autofocus
                                               :class="{'p-invalid': submittedPA && !planAction.title}"/>
                                    <small class="p-invalid"
                                           v-if="submittedPA && !planAction.title">{{ t('titleRequired') }}</small>
                                </div>
                                <div class="field-control col">
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="titre">{{ t('reference') }} </label>
                                    <InputText id="name" v-model="planAction.reference" autofocus/>
                                </div>
                                <div class="field-control col">
                                    <label for="statut">{{ t('subDomain') }}</label>
                                    <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                                                placeholder="Select Item"
                                                @node-select="selectNode"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label>{{ t('divers') }} </label>
                                    <Textarea id="description" v-model="planAction.others"/>
                                </div>
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('priority') }} </label>
                                    <Dropdown v-model="priority" :options="valuesPriorities" optionLabel="name"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="titre">{{ t('process') }} </label>
                                    <InputText id="name" v-model="planAction.process"
                                    />
                                </div>
                                <div class="field-control col">
                                    <label for="titre">{{ t('risksopportunities') }} </label>
                                    <InputText id="name" v-model="planAction.risks_opportunities"
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>Pilotage</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="titre">{{ t('startDate') }} <span>*</span></label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date"
                                              dateFormat="dd/mm/yy"
                                              :class="{'p-invalid': submittedPA && !start_date}"></Calendar>
                                    <small class="p-invalid" v-if="submittedPA && !start_date">{{
                                        t('startDateRequired')
                                        }}</small>
                                </div>
                                <div class="field-control col">
                                    <label for="titre">{{ t('endDate') }} <span>*</span></label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date"
                                              dateFormat="dd/mm/yy" :class="{'p-invalid': submittedPA && !end_date}"
                                              @update:modelValue="changeEndDate($event)"></Calendar>
                                    <small class="p-invalid" v-if="submittedPA && !end_date">{{
                                        t('endDateRequired')
                                        }}</small>
                                </div>
                                <div class="field-control col">
                                    <label for="titre">{{ t('revisedEndDate') }} <span>*</span></label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="revised_end_date"
                                              dateFormat="dd/mm/yy"
                                              :class="{'p-invalid': submittedPA && !revised_end_date}"></Calendar>
                                    <small class="p-invalid" v-if="submittedPA && !revised_end_date">{{
                                        t('revisedEndDateRequired')
                                        }}</small>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('apPilot') }} </label>
                                    <Dropdown v-model="apPilot" :options="listUsers" optionLabel="user_name"/>
                                </div>
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('responsibleAP') }}<span>*</span> </label>
                                    <Dropdown v-model="responsibleAP" :options="listUsers" optionLabel="user_name"
                                              required="true"
                                              :class="{'p-invalid': submittedPA && !responsibleAP}"/>
                                    <small class="p-invalid"
                                           v-if="submittedPA && !responsibleAP">{{ t('responsibleAPRequired') }}</small>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('typeProgresion') }} </label>
                                    <Dropdown v-model="typeProgress" :options="listTypeProgression" optionLabel="value"
                                              required="true"/>
                                </div>
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('rateProgress') }} </label>
                                    <Dropdown v-model="rateProgress" :options="listRateProgression"
                                              optionLabel="label"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <div class="field-radiobutton pt-4">
                                        <Checkbox id="checkOption1" name="option" :binary="true"
                                                  v-model="planAction.reminder_fixed"/>
                                        <label for="confidentiel">{{ t('fixedReminder') }}</label>
                                    </div>
                                </div>
                                <div class="field-control col">
                                    <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
                                    <InputNumber id="name" v-model="planAction.reminder_before_deadline" autofocus/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <div class="field-radiobutton pt-4">
                                        <Checkbox id="checkOption1" name="option" :binary="true"
                                                  v-model="planAction.reminder_periodic"/>
                                        <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
                                    </div>
                                </div>
                                <div class="field-control col">
                                    <label for="titre">{{ t('periodicReminderTime') }} </label>
                                    <InputNumber id="name" v-model="planAction.periodic_reminder_time" autofocus/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('measureEffectiveness') }}</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label>{{ t('verificationType') }} </label>
                                    <Textarea id="description" v-model="planAction.verification_type"/>
                                </div>
                                <div class="field-control col">
                                    <label>{{ t('comment') }} </label>
                                    <Textarea id="description" v-model="planAction.comment"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label for="titre">{{ t('verificationDate') }} <span>*</span></label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="verification_date"
                                              dateFormat="dd/mm/yy">
                                    </Calendar>
                                </div>
                                <div class="field-control col">
                                    <label for="Priorite">{{ t('responsibleVerification') }} </label>
                                    <Dropdown v-model="verifier_user" :options="listUsers" optionLabel="user_name"/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('resources') }}</span>
                            </template>

                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label>{{ t('financialRsources') }} </label>
                                    <Textarea id="description" v-model="planAction.financial_resources"/>
                                </div>

                                <div class="field-control col">
                                    <label>{{ t('operationalResource') }} </label>
                                    <Textarea id="description" v-model="planAction.operational_resource"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field-control col">
                                    <label>{{ t('technologicalResources') }} </label>
                                    <Textarea id="description" v-model="planAction.technological_resources"/>
                                </div>

                                <div class="field-control col">
                                    <label>{{ t('humanResources') }} </label>
                                    <Textarea id="description" v-model="planAction.humain_resources"/>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('comments') }}</span>
                            </template>

                            <DataTable :value="actionPlanDetails.action_plan_comments"
                                       :totalRecords="totalItemsTasks"
                                       dataKey="id" :paginator="true" :rows="30"
                                       :rowHover="true"
                                       class="p-datatable-gridlines table-filter-texte"
                                       :loading="loadingComments"
                                       responsiveLayout="scroll">
                                <template #loading>
                                    Loading data. Please wait.
                                </template>

                                <template #empty>
                                    {{ t('NoDataToDisplay') }}
                                </template>
                                <template #header>
                                    <div class="table-header-container">
                                        <Button :label="t('addingComments')" icon="pi pi-plus"
                                                class="add-text p-button-success mr-2"
                                                @click="openModalComments()"/>
                                    </div>
                                </template>

                                <Column field="name" :header="t('comment')" style="width:10rem">
                                    <template #body="{data}">
                                        <div class="tooltip"
                                             v-tooltip.right="'<p><strong>'+t('comment')+ ':</strong>'+ data.comment+'</p>'">
                                            <p>{{ data.comment }}</p>
                                        </div>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('creationDate')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ formatDate(data.created_at) }}</p>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('createdBy')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ data?.created_by?.firstname }}{{ data?.created_by?.lastname }}</p>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('updatedAt')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ formatDate(data.updated_at) }}</p>
                                    </template>
                                </Column>

                                <Column field="name" :header="t('updatedBy')" style="width:10rem">
                                    <template #body="{data}">
                                        <p>{{ data?.updated_by?.firstname }}{{ data?.updated_by?.lastname }}</p>
                                    </template>
                                </Column>

                                <Column style="min-width:4rem">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                                                @click="openModalComments(slotProps.data)"
                                                v-tooltip.top="{value:t('edit'), class: 'editBtn'}"
                                        />
                                        <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                                                @click="deleteComment(slotProps.data)"
                                                v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                                                v-if="!['1','3'].includes(role_user_site)"/>
                                    </template>
                                </Column>

                            </DataTable>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogEditPAEval"/>
                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="savePAEdit()" :disabled="btnAPSubmit" v-if="!['1','3'].includes(role_user_site)"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="tasksDialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="titre">{{ t('title') }}<span>*</span> </label>
                            <InputText id="name" v-model="task.title" autofocus
                                       :class="{'p-invalid': submittedTasks && !task.title}"/>
                            <small class="p-invalid" v-if="submittedTasks && !task.title">{{
                                t('titleRequired')
                                }}</small>
                        </div>
                        <div class="field-control col">
                            <label for="Priorite">{{ t('responsibleTasks') }}</label>
                            <Dropdown v-model="responsibleTask" :options="listUsers" optionLabel="user_name"/>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="titre">{{ t('startDate') }} <span>*</span></label>
                            <Calendar :showIcon="true" :showButtonBar="true" v-model="start_date_task"
                                      dateFormat="dd/mm/yy"></Calendar>
                            <small class="p-invalid" v-if="submittedTasks && !start_date_task">{{
                                t('startDateRequired')
                                }}</small>
                        </div>
                        <div class="field-control col">
                            <label for="titre">{{ t('endDate') }} <span>*</span></label>
                            <Calendar :showIcon="true" :showButtonBar="true" v-model="end_date_task"
                                      dateFormat="dd/mm/yy"></Calendar>
                            <small class="p-invalid" v-if="submittedTasks && !end_date_task">{{
                                t('endDateRequired')
                                }}</small>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="titre">{{ t('thematic') }}</label>
                            <InputText id="name" v-model="task.thematic" autofocus/>
                        </div>
                        <div class="field-control col">
                            <label for="Priorite">{{ t('rateProgress') }} </label>
                            <Dropdown v-model="rateProgress_task" :options="listRateProgression" optionLabel="label"/>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="description">Description </label>
                            <Textarea id="description" v-model="task.description" rows="5" cols="20"/>
                        </div>
                        <div class="field-control col">
                            <label for="description">{{ t('comment') }} </label>
                            <Textarea id="description" v-model="task.comment" rows="5" cols="20"/>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <div class="field-radiobutton pt-4">
                                <Checkbox id="checkOption1" name="option" :binary="true" v-model="task.reminder_fixed"/>
                                <label for="confidentiel">{{ t('fixedReminder') }}</label>
                            </div>
                        </div>

                        <div class="field-control col">
                            <label for="titre">{{ t('nbDaysBeforeAPDeadline') }} </label>
                            <InputNumber id="name" v-model="task.reminder_before_deadline" autofocus/>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field-control col">
                            <div class="field-radiobutton pt-4">
                                <Checkbox id="checkOption1" name="option" :binary="true"
                                          v-model="task.reminder_periodic"/>
                                <label for="confidentiel">{{ t('periodicRelaunch') }}</label>
                            </div>
                        </div>

                        <div class="field-control col">
                            <label for="titre">{{ t('periodicReminderTime') }} </label>
                            <InputNumber id="name" v-model="task.periodic_reminder_time" autofocus/>
                        </div>
                    </div>


                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogTask"/>
                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="saveTask()" v-if="!['1','3'].includes(role_user_site)"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="commentsDialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
                    <div class="formgrid grid">
                        <div class="field-control col">
                            <label for="titre">{{ t('comment') }}<span>*</span> </label>
                            <textarea id="name" v-model="commentAP.comment" autofocus
                                      :class="{'p-invalid': submittedComments && !commentAP.comment}" rows="5"
                                      cols="20"/>
                            <small class="p-invalid"
                                   v-if="submittedComments && !commentAP.comment">{{ t('commentRequired') }}</small>
                        </div>

                    </div>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogComment"/>
                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="saveComment()" v-if="!['1','3'].includes(role_user_site)"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="complianceDialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" header="Identification">
                    <DataTable :value="documents" v-model:expandedRows="expandedRowsDoc" :lazy="true"
                               :totalRecords="totalItemsDoc"
                               @page="onPageDoc($event)" dataKey="id" :paginator="true" :rows="nbItems"
                               :rowsPerPageOptions="[5,10,30,50]"
                               :rowHover="true"
                               class="p-datatable-gridlines table-filter-texte"
                               @rowExpand="onRowExpandDoc($event.data.id,1)"
                               filterDisplay="menu" :loading="loadingDoc"
                               responsiveLayout="scroll"
                               :globalFilterFields="['titleDescription','theme','domain','subDomain']"
                               @filter="filterDataTableCompliance($event)"
                               v-model:filters="filtersCompliances" :filters="filtersCompliances">
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <template #header>
                            <div class="table-header-container">
                                <Button type="button" icon="pi pi-minus" :label="t('cutThroughAll')"
                                        @click="collapseAll"
                                        class="add-text p-button-help mr-2"/>
                            </div>
                        </template>
                        <Column :expander="true" headerStyle="width: 1rem" style="width: 1rem;padding: 2px !important;"
                                class="toggler_chevron"/>
                        <template #empty>
                            {{ t('NoDataToDisplay') }}
                        </template>
                        <Column header="" class="td_flag">
                            <template #body="slotProps">
                                <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="20"
                                     v-if="slotProps?.data?.live_status?.color==='black'"/>
                                <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="20"
                                     v-if="slotProps?.data?.live_status?.color==='red'"/>
                                <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="20"
                                     v-if="slotProps?.data?.live_status?.color==='yellow'"/>
                                <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="20"
                                     v-if="slotProps?.data?.live_status?.color==='green'"/>
                            </template>
                        </Column>
                        <Column field="name" :header="t('text')" style="width:10rem" filterField="titleDescription"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                                <div class="tooltip"
                                     v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p><p><strong>Description :</strong> '+
                    data.description+'</p><p><strong>'+t('fieldApplication') +': </strong>'+data.application_fields+'</p>'">
                                    <p>{{ data.title }}</p>
                                    <p v-html="data.description" v-if="data.description !== 'null'" class="text-light"></p>
                                </div>
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                           :placeholder="t('search')"/>
                            </template>
                        </Column>
                        <Column :header="t('theme')" style="min-width:8rem" filterField="theme"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
              <span style="vertical-align: middle" v-if="data.theme_id"
                    class="image-text">{{ data.theme_id }}</span>
                            </template>
                            <template #filter="{filterModel}">
                                <Dropdown v-model="filterModel.value" :options="categoriesTheme" placeholder="Any"
                                          class="p-column-filter"
                                          :showClear="true">
                                    <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value"
                        v-if="slotProps.value">{{ slotProps.value.label }}</span>
                                        <span v-else>{{ t('select') }}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                                            slotProps.option.label
                                            }}</span>
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column :header="t('domain')" filterField="domain" :showFilterMatchModes="false"
                                style="min-width:8rem">
                            <template #body="{data}">
              <span style="vertical-align: middle" class="image-text" v-if="data.domain_id">{{
                  data.domain_id
                  }}</span>
                            </template>
                            <template #filter="{filterModel}">
                                <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder="Any"
                                          class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value"
                        v-if="slotProps.value">{{ slotProps.value.label }}</span>
                                        <span v-else>{{ t('select') }}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                                            slotProps.option.label
                                            }}</span>
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                        <Column :header="t('subDomain')" filterField="subDomain" :showFilterMatchModes="false"
                                style="min-width:8rem">
                            <template #body="{data}">
               <span style=" vertical-align: middle" class="image-text" v-if="data.sub_domain_id">
                     {{ data.sub_domain_id }}
               </span>
                            </template>
                            <template #filter="{filterModel}">
                                <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder="Any"
                                          class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value"
                        v-if="slotProps.value">{{ slotProps.value.label }}</span>
                                        <span v-else>{{ t('select') }}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                                            slotProps.option.label
                                            }}</span>
                                    </template>
                                </Dropdown>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div class="orders-subtable eval_level_1">
                                <DataTable :value="slotProps.data.sections" responsiveLayout="scroll"
                                           :lazy="true"
                                           v-model:expandedRows="expandedRowsSec"
                                           @rowExpand="onRowExpandSection($event.data, 1)"
                                           :loading="loadingSection[slotProps.data.id]"
                                           :paginator="true" :rows="10" :row-class="rowClass"
                                           :totalRecords="totalItemsSect[slotProps.data.id]"
                                           @page="onPageSec(slotProps.data.id, $event.page+1)">
                                    <Column :expander="true" headerStyle="width: 3rem">
                                    </Column>
                                    <template #empty>
                                        {{ t('NoDataToDisplay') }}
                                    </template>
                                    <Column field="text" style="width:50rem">
                                        <template #body="slotProp">
                                            {{ truncate(slotProp.data.title) }}
                                        </template>
                                    </Column>
                                    <template #expansion="slotProps">
                                        <div class="orders-subtable eval_level_2">
                                            <h5>{{ slotProps.data.name }}</h5>
                                            <DataTable :value="slotProps.data.compliances" responsiveLayout="scroll"
                                                       :lazy="true"
                                                       :totalRecords="totalItemsCompliance[slotProps.data.id]"
                                                       @page="onpageCompliance(slotProps.data, $event)"
                                                       :loading="loadingCompliance[slotProps.data.id]" :paginator="true"
                                                       :rows="10"
                                                       v-model:selection="selectedCompliances[slotProps.data.id]">
                                                <template #empty>
                                                    {{ t('NoDataToDisplay') }}
                                                </template>
                                                <Column selectionMode="multiple"
                                                        style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>
                                                <Column field="text" :header="t('titleOfCompliance')"
                                                        style="width:10rem">
                                                    <template #body="slotProps">
                                                        <div class="tooltip"
                                                             v-tooltip.right="'<p><strong>'+t('novalliaAnnotation')+ ': </strong>'+ slotProps.data.title+
                                     '</p><p><strong>'+ t('customerAnnotation')+ ' :</strong> '+ slotProps.data.evaluations[0].annotation+' </p><p><strong>'+ t('comment')+' :</strong>'+ slotProps.data.evaluations[0].comment+
                                     '</p><p><strong>'+ t('valuationDate') + ' :</strong>'+  formatDate(slotProps.data.evaluations[0].evaluation_date)  +'</p>'">

                                                            <p v-if="isRte">{{ truncateText(slotProps.data.title_rte) }}</p>
                                                            <p v-else>{{ truncateText(slotProps.data.titleSection) }}</p>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="text" :header="t('textOfCompliance')"
                                                        style="width:50rem">
                                                    <template #body="slotProps">
                                                        <span v-html="slotProps.data.title"> </span>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </template>
                                </DataTable>
                            </div>
                        </template>
                    </DataTable>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogCompliance"/>
                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                               :disabled="btnAPSubmit" @click="saveCompliances()" v-if="!['1','3'].includes(role_user_site)"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="attachPADialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" :header="t('listOfActionsPlans')">
                    <DataTable :value="actionPlanListAttach" :lazy="true" :totalRecords="totalItemsAPAttach"
                               @page="onPageActionPlanAttach($event.page +1)" dataKey="id" :paginator="true" :rows="30"
                               :rowHover="true"
                               class="p-datatable-gridlines table-filter-texte"
                               v-model:filters="filters2" filterDisplay="menu" :loading="loadingAPAttach"
                               :filters="filters2"
                               responsiveLayout="scroll"
                               :globalFilterFields="['title','start_date','end_date','revised_end_date','user_pilot','user_in_charge','rate_progress','priority']"
                               @filter="filterDataTableAttach($event)"
                               v-model:selection="selectedAPAttach">
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <!--            <template #header>-->
                        <!--              <div class="table-header-container">-->
                        <!--                <Button :label="t('addActionPlan')" icon="pi pi-plus" class="add-text p-button-success mr-2"-->
                        <!--                        @click="openModalAP()"/>-->
                        <!--              </div>-->
                        <!--            </template>-->
                        <template #empty>
                            {{ t('NoDataToDisplay') }}
                        </template>
                        <Column selectionMode="multiple"
                                style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>
                        <Column field="name" :header="t('text')" style="width:10rem" filterField="title"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                                <div class="tooltip"
                                     v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p>'">
                                    <p>{{ data.title }}</p>

                                </div>

                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                           :placeholder="t('searchByName')"/>
                            </template>
                        </Column>
                        <Column :header="t('startDate')" style="min-width:8rem" filterField="start_date"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.start_date) }}</span>
                            </template>
                            <template #filter="{filterModel}">
                                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
                            </template>
                        </Column>
                        <Column :header="t('endDate')" style="min-width:8rem" filterField="end_date"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.end_date) }}</span>
                            </template>
                            <template #filter="{filterModel}">
                                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
                            </template>
                        </Column>
                        <Column :header="t('revisedEndDate')" style="min-width:8rem" filterField="revised_end_date"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.revised_end_date) }}</span>
                            </template>
                            <template #filter="{filterModel}">
                                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
                            </template>
                        </Column>
                        <Column field="name" :header="t('apPilot')" style="width:10rem" filterField="user_pilot"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                                <p>{{ data?.user_pilot?.firstname }} {{ data?.user_pilot?.lastname }}</p>
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                           :placeholder="t('searchByName')"/>
                            </template>
                        </Column>
                        <Column field="name" :header="t('responsibleAP')" style="width:10rem"
                                filterField="user_in_charge"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                                <p>{{ data.user_in_charge?.firstname }} {{ data.user_in_charge?.lastname }}</p>
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                           :placeholder="t('searchByName')"/>
                            </template>
                        </Column>
                        <Column field="name" :header="t('tasks')" style="width:10rem">
                            <template #body="{data}">
                                {{ data?.action_plan_tasks?.length }}
                            </template>
                        </Column>

                        <Column style="min-width:4rem">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="btn_file_pdf button-table mr-2"
                                        @click="modalEditPAEval(slotProps.data)"
                                        v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                                <!--                                <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"-->
                                <!--                                        @click="deleteAPEval(slotProps.data)"-->
                                <!--                                        v-tooltip.top="{value:t('delete'), class: 'editBtn'}" v-if="role_user_site != 1"/>-->
                            </template>
                        </Column>
                    </DataTable>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogAttachPA"/>
                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                               :disabled="disableAttachPA" @click="saveAttachPA" v-if="!['1','3'].includes(role_user_site)"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="transversalEvaluationsDialog" :style="{width: '900px'}"
                        :modal="true" class="modal_general p-fluid spaceTitle" :header="t('transversalEvaluation')">
                    <DataTable :value="listEvaluationsByDocument" responsiveLayout="scroll"
                               :totalRecords="totalItemsEvaluationByDocument"
                               :loading="loadingEvaluationDocument" :paginator="true" :rows="5"
                               class="p-datatable-gridlines table-filter-texte"
                               v-model:selection="selectedEvaluations" style="margin-bottom: 1rem;"
                               v-model:filters="filtersEvaluation" filterDisplay="menu"
                               :globalFilterFields="['title_section_compliance']">
                        <template #empty>
                            {{ t('NoDataToDisplay') }}
                        </template>
                        <Column selectionMode="multiple"
                                style="width: 0.2rem;text-align: center !important;padding: 0 !important;"></Column>

                        <Column field="title_section_compliance" :header="t('text')" style="width:10rem"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                                <div class="tooltip"
                                     v-tooltip.bottom="'<p><strong> '+  t('titleOfCompliance') +' : </strong>'+data.title_section+'</p><p><strong>'+ t('textOfCompliance') +' : </strong>'+
                      data.title_compliance.replace(/^<p>/i, '')+'</p>'">
                                    <p class="one-column" v-html="data.title_section"></p>
                                    <p class="one-column" v-html="data.title_compliance.replace(/^<p>/i, '')"></p>
                                </div>
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                           :placeholder="t('search')"/>
                            </template>
                        </Column>
                    </DataTable>

                    <DataTable :value="listSitesAssignToDocument" :totalRecords="totalItemsSitesAssignToDocument"
                               dataKey="index" :paginator="true" :rows="5"
                               :rowHover="true"
                               class="p-datatable-gridlines table-filter-texte"
                               v-model:filters="filtersSiteEvaluation" filterDisplay="menu"
                               :loading="loadingSiteAssignToDocument"
                               responsiveLayout="scroll"
                               :globalFilterFields="['site_name']"
                               v-model:selection="selectedSiteAssignToDocument">
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <template #empty>
                            {{ t('NoDataToDisplay') }}
                        </template>

                        <Column selectionMode="multiple"
                                headerStyle="width: 2rem; text-align: center !important;"
                                ></Column>

                        <Column field="site_name" :header="t('name')" filterField="site_name"
                                :showFilterMatchModes="false">
                            <template #body="{data}">
                                <p>{{ data?.site_name }}</p>
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                           :placeholder="t('search')"/>
                            </template>
                        </Column>

                    </DataTable>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialogDuplicateEvaluation"/>
                        <Button :label="t('save')" icon="pi pi-check" class="p-button-text next_btn"
                                @click="duplicateEvaluation"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
    <Toast/>
</template>
<script>

import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {FilterMatchMode} from "primevue/api";
import moment from 'moment-timezone'
import {computed} from "vue";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import AxProgressBar from "@/components/AxProgressBar";
import {saveAs} from 'file-saver';
import HotlineService from "@/service/HotlineService";
import Editor from "primevue/editor";

export default {
    components: {
        AxProgressBar,
        Editor
    },
    data() {
        return {
            valueProgress: 0,
            documents: [],
            sections: [],
            compliances: [],
            expandedRowsDoc: [],
            expandedRowsSec: [],
            filters1: null,
            loadingDoc: true,
            loadingSection: {},
            loadingCompliance: {},
            selectedDocuments: null,
            evaluationDialog: false,
            text: {},
            valuesEvaluations: [],
            valueEvaluation: '',
            evaluation: {},
            totalItemsDoc: 0,
            totalItemsSect: {},
            totalItemsCompliance: {},
            totalItemsCompliancePA: 0,
            idEvaluation: '',
            idCompliance: '',
            idSection: '',
            evaluation_date: '',
            evaluationStatus: '',
            nbItems: 10,
            section: '30',
            nbPageCompliance: 1,
            nbpageSection: 1,
            nbPageDocument: 1,
            categoriesTheme: [],
            categoriesDomain: [],
            categoriesSubDomain: [],
            categoriesThemeDocument: [],
            categoriesDomainDocument: [],
            categoriesSubDomainDocument: [],
            textAdmin: '',
            categoryValueTheme: '',
            categoryValueDomain: '',
            categoryValueSubDomain: '',
            submitted: false,
            submittedPA: false,
            submittedEval: false,
            tabCategoriesTheme: [],
            tabCategoriesDomain: [],
            tabCategoriesSubDomain: [],
            id_document: '',
            obj_document: '',
            historicEvaluation: [],
            verifAdmin: true,
            verifSuperAdmin: false,
            titleDialogEvaluation: '',
            totalItemsOfDocument: '0',
            pageHistEval: 1,
            totalItemsHistEval: '',
            totalComplianceEvaluer: 0,
            totalComplianceReevaluer: 0,
            titleGlobalDocument: '',
            categoriesThemeConst: [],
            categoriesDomainConst: [],
            categoriesSubDomainConst: [],
            importDocumentDialog: false,
            isHotline: false,
            hotlineTitle: 'Hotline',
            dataCurrentDiscussion: null,
            fileArray: [],
            textAreaValue: '',
            sendClicked: false,
            fileImportDocuments: '',
            countInterval: null,
            actionPlanList: [],
            totalItemsAP: 0,
            nbPageActionPlan: 1,
            PADialog: false,
            planAction: {},
            valueSubDomain: '',
            tabCategories: [],
            priority: '',
            valuesPriorities: [{id: 1, name: 'Mineure', value: '78'}, {id: 2, name: 'Moyenne', value: '79'}, {
                id: 3,
                name: 'Majeure',
                value: '80'
            }, {
                id: 0,
                name: 'Aucune',
                value: '228'
            },],
            start_date: '',
            end_date: '',
            revised_end_date: '',
            listUsers: [],
            responsibleAP: '',
            apPilot: '',
            listRateProgression: [
                {id: 1, value: 0, label: '0%',},
                {id: 2, value: 20, label: '20%'},
                {id: 3, value: 40, label: '40%'},
                {id: 4, value: 60, label: '60%'},
                {id: 5, value: 80, label: '80%'},
                {id: 6, value: 100, label: '100%'}],
            rateProgress: {id: 1, value: 0, label: '0%'},

            listTypeProgression: [
                {id: 1, value: 'Par le chargé du plan d\'actions'},
                {id: 1, value: 'Avancement des tâches'},
            ],
            typeProgress: {id: 1, value: 'Par le chargé du plan d\'actions'},
            nbPageHistoric: 0,
            PAEvalEditDialog: false,
            attachPADialog: false,
            selectedAPAttach: null,
            actionPlanListAttach: [],
            totalItemsAPAttach: 0,
            filters2: null,
            nbPageActionPlanAttach: 1,
            loadingAPAttach: false,
            loadingAPList: false,
            rateProgressEvalFilter: '',
            rateConformityEvalFilter: '',
            transversalEvaluationsDialog: false,
            listSitesAssignToDocument: [],
            totalItemsSitesAssignToDocument: 0,
            loadingSiteAssignToDocument: false,
            selectedSiteAssignToDocument: [],
            filtersSiteEvaluation: null,
            listSites: [],
            listEvaluationsByDocument: [],
            totalItemsEvaluationByDocument: 0,
            loadingEvaluationDocument: false,
            nbPageEvaluationDocument: 1,
            selectedEvaluations: [],
            filtersEvaluation: null,
            textEvaluationDocument: '',
            btnAPSubmit: false,
            btnEvaluationSubmit: false,
            load: false,
            role_user_site: false,
            titleAP: '',
            tasksDialog: false,
            task: {},
            start_date_task: '',
            end_date_task: '',
            rateProgress_task: {id: 1, value: 0, label: '0%',},
            responsibleTask: '',
            submittedTasks: false,
            totalItemsTasks: 0,
            loadingTasks: false,
            loadingComments: false,
            loadingCompliancesPA: false,
            actionPlanDetails: '',
            commentAP: {},
            commentsDialog: false,
            submittedComments: false,
            complianceDialog: false,
            filtersCompliances: null,
            selectedCompliances: [],
            evaluationsAP: [],
            disableAttachPA: false,
            verification_date: '',
            fileEvaluation : null,
            evaluation_file: null,
            evaluationFileDeleted: false,
            compliance_id: null,
            upload_file_evaluation: false,
            actual_user_name: '',
            actual_site_name: '',
            btnEvaluationFile: false,
        }
    },
    interval: null,
    hotlineService: null,
    setup() {
        const primevue = usePrimeVue();
        const store = useStore();
        const id_site = computed(() => {
            return store.getters.currentSite.id
        });
        const user = computed(() => {
            return store.getters.currentUser
        });
        const toast = useToast();
        const countStatusImport = computed(() => {
            return store.getters.toolsObject.countStatusImport
        })
        let user_id = user.value?.id;
        const t = primevue.config.StoreTranslation.t
        const year_customer = computed(() => {
            return store.getters.getYearCustomer
        })
        const year_site = computed(() => {
            return store.getters.getYearSite
        })

        const translation = computed(() => {
            return store.getters.getCurrentLanguage
        })
        const isRte = computed(() => {
            return store.getters.isRte
        })
        return {store, id_site, toast, primevue, t, user, user_id, countStatusImport, year_customer, translation, isRte, year_site};
    },
    async created() {
        this.initFilters1();
        this.hotlineService = new HotlineService()
    },
    async beforeMount() {
        await this.getStatusImport()
    },
    async mounted() {
        this.startProgress();
        if (this.user?.application_master) {
            this.verifSuperAdmin = true
        }
        this.countInterval = setInterval(() => {
            this.getStatusImport()
        }, 30000)
        await this.getCategoriesByTheme();
        await this.getStatusEvaluation();
        // console.log(this.documents, 'totalItemsDoc')
        // if (this.totalItemsDoc < 1) {
        if (this.id_site && !this.load)
            await this.getDocuments(1, this.nbItems);
        // }
        await this.getActionPlansInit(1, 'attach');
        await this.getUsersAP()
        if (this.id_site && this.user_id) {
            this.getUserSiteRole()
        }
    },
    beforeUnmount() {
        this.endProgress();
        clearInterval(this.countInterval)
    },
    watch: {
        id_site() {
            if (!this.load)
                this.getDocuments(1, this.nbItems);

            this.getUserSiteRole()
        },
        user() {
            if (this.user?.role_user && this.user.role_user[0] !== 'ROLE_ADMIN') {
                this.verifAdmin = false
            }
            if (this.user?.application_master) {
                this.verifSuperAdmin = true
            }
            this.user_id = this.user?.id
            this.getUserSiteRole()
        },
        translation() {
            this.getRecapEvalStat()
        },
        countStatusImport() {
            if (this.countStatusImport < 1) {
                this.loadingDoc = false
                this.getDocuments(1, this.nbItems);
            } else {
                this.loadingDoc = true
            }
        }
    },
    methods: {
        uploadFile(event) {
            console.log(event)
        },
        handleFileUpload(event) {
            this.fileArray = event.files;
        },
        sendDiscussion() {
            this.sendClicked = true;
            let files = this.$refs.fileUploadDiscussion?.files
            let filedId = [];
            files.forEach((f, i) => {
                let json = {
                    type: f.type,
                    name: f.name,
                    size: f.size,
                    file: f,
                }
                this.hotlineService.uploadFile(json).then((data) => {
                    if (data.status === 201) {
                        filedId.push(data.data.id)
                        if (i === files.length - 1) {
                            this.postSendDiscussion(filedId)
                        }
                    }
                })
            })
            if (files.length === 0) {
                this.postSendDiscussion(filedId)
            }
        },
        postSendDiscussion(filedId) {
            let payload = {
                documentId: this.dataCurrentDiscussion?.id,
                topic: this.dataCurrentDiscussion?.title,
                message: this.textAreaValue,
                files: filedId,
                currentUserId: this.user.id,
                currentSiteId: this.id_site
            }
            this.hotlineService.sendDiscussion(payload).then((data) => {
                if (data.status === 201) {
                    this.isHotline = false;
                    this.textAreaValue = '';
                }
            })
        },
        hideOpenDiscussion() {
            this.isHotline = false;
            this.fileArray = [];
            this.textAreaValue = ''
        },
        openDiscussion(data) {
            this.sendClicked = false
            this.hotlineTitle = data?.title;
            this.dataCurrentDiscussion = data;
            this.isHotline = true;
            this.fileArray = [];
            this.textAreaValue = '';
        },
        editDocument(data) {
            console.log(data)
        },
        startProgress() {
            this.interval = setInterval(() => {
                let newValue = this.valueProgress + Math.floor(Math.random() * 10) + 1;
                if (newValue >= 50) {
                    newValue = 50;
                }
                this.valueProgress = newValue;
            }, 100);
        },
        endProgress() {
            clearInterval(this.interval);
            this.interval = null;
        },
        initFilters1() {
            this.filters1 = {
                'titleDescription': {value: null, matchMode: FilterMatchMode.EQUALS},
                'dated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
                'last_updated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
                'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
                'domain': {value: null, matchMode: FilterMatchMode.EQUALS},
                'subDomain': {value: null, matchMode: FilterMatchMode.EQUALS},
                'rate_progress': {value: null, matchMode: FilterMatchMode.EQUALS},
                'rate_conformity': {value: null, matchMode: FilterMatchMode.EQUALS},
            }
            this.filters2 = {
                'title': {value: null, matchMode: FilterMatchMode.EQUALS},
                'start_date': {value: null, matchMode: FilterMatchMode.EQUALS},
                'end_date': {value: null, matchMode: FilterMatchMode.EQUALS},
                'revised_end_date': {value: null, matchMode: FilterMatchMode.EQUALS},
                'user_pilot': {value: null, matchMode: FilterMatchMode.EQUALS},
                'user_in_charge': {value: null, matchMode: FilterMatchMode.EQUALS},
                'rate_progress': {value: null, matchMode: FilterMatchMode.EQUALS},
                'priority': {value: null, matchMode: FilterMatchMode.EQUALS},
            }
            this.filtersSiteEvaluation = {
                'site_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
            this.filtersEvaluation = {
                'title_section_compliance': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
            this.filtersCompliances = {
                'titleDescription': {value: null, matchMode: FilterMatchMode.EQUALS},
                'dated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
                'last_updated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
                'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
                'domain': {value: null, matchMode: FilterMatchMode.EQUALS},
                'subDomain': {value: null, matchMode: FilterMatchMode.EQUALS},
            }

        },
        clearFilter1() {
            this.initFilters1();
        },
        getDocuments(page = 1, nbItems) {
            this.load = true
            this.loadingDoc = true;
            this.documents = []
            this.nbItems = nbItems
            let idTheme = []
            let idDomain = []
            let idSubDomain = []
            if (this.categoryValueTheme?.length > 0 && !this.categoryValueSubDomain) {
                for (let i = 0; i < this.categoryValueTheme.length; i++) {
                    idTheme.push(this.categoryValueTheme[i].id)

                }
            }
            if (this.categoryValueDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueDomain.length; i++) {
                    idDomain.push(this.categoryValueDomain[i].id)
                }
            }
            if (this.categoryValueSubDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueSubDomain.length; i++) {
                    idSubDomain.push(this.categoryValueSubDomain[i].id)
                }
            }
            this.categoriesThemeDocument = []
            this.categoriesDomainDocument = []
            this.categoriesSubDomainDocument = []
            return this.store.dispatch(Actions.GET_DOCUMENTS_STAT, {
                'idSite': this.id_site,
                // 'withStat': 1,
                'page': page,
                'limit': this.nbItems,
                'theme': idTheme.length > 0 ? idTheme : '',
                'domain': idDomain.length > 0 ? idDomain : '',
                'sub_domain': idSubDomain.length > 0 ? idSubDomain : '',
                'text': this.textAdmin,
                'customHeader': true,
                'rate_progress': this.rateProgressEvalFilter,
                'rate_conformity': this.rateConformityEvalFilter,
            }).then(async data => {
                if (data) {
                    this.expandedRowsDoc = [];
                    this.totalItemsDoc = data['hydra:member'][1]
                    data['hydra:member'][0].forEach(element => {
                        let objTheme = this.categoriesThemeConst.find(o => o.id == element.theme_id);
                        let objDomain = this.categoriesDomainConst.find(o => o.id == element.domain_id);
                        let objSubDomain = this.categoriesSubDomainConst.find(o => o.id == element.sub_domain_id);
                        this.categoriesThemeDocument.push(element.theme_id)
                        this.categoriesDomainDocument.push(element.domain_id)
                        this.categoriesSubDomainDocument.push(element.sub_domain_id)
                        if (objTheme) {
                            element['theme_id'] = objTheme.name
                        } else {
                            element['theme_id'] = objTheme
                        }
                        if (objDomain) {
                            element['domain_id'] = objDomain.name

                        } else {
                            element['domain_id'] = objDomain
                        }
                        if (objSubDomain) {
                            element['sub_domain_id'] = objSubDomain.name

                        } else {
                            element['sub_domain_id'] = objSubDomain
                        }
                    })
                    this.documents = data['hydra:member'][0]
                    // this.getStatusImport()
                    this.loadingDoc = this.countStatusImport >= 1;
                    await this.getRecapEvalStat()
                }
            }).catch(() => {
            })
        },
        onRowExpandDoc(documentId, page = 1) {
            this.loadingSection[documentId] = true;
            let id_Doc = documentId;
            let objectDocument = this.documents.find(element => element.id == id_Doc)
            this.store.dispatch(Actions.GET_SECTIONS_STAT, {
                'document': id_Doc,
                'site': this.id_site,
                'withStat': 1,
                'page': page,
                'customHeader': true,
                'limit': 10,
            }).then(data => {
                this.loadingSection[documentId] = false;
                this.sections = data['hydra:member'][0]
                this.totalItemsSect[documentId] = data['hydra:member'][1]
                this.sections = this.sections.map((data) => ({
                    ...data,
                    id_document: documentId,
                }));

                Object.assign(objectDocument, {'sections': this.sections, 'id_document': documentId})
            }).catch(() => {
            })
        },
        onRowExpandSection(dataSection, page = 1) {
            let documentsTab = []
            this.loadingCompliance[dataSection.id] = true;
            let id_sect = dataSection.id
            let id_doc = dataSection.id_document
            return this.store.dispatch(Actions.GET_COMPLIANCES, {
                'section': id_sect,
                'evaluationSite': this.id_site,
                'page': page,
                'customHeader': true,
                'limit': 10,
                'evaluationDocument' : id_doc,
            }).then(data => {
                this.loadingCompliance[dataSection.id] = false;
                this.compliances = data['hydra:member'].map((data) => ({
                    ...data,
                    titleSection: dataSection.title,
                    idSection: dataSection.id,
                    id_document: dataSection.id_document,
                }));
                documentsTab = this.documents
                documentsTab.find((item, index) => {
                    if (item.id === id_doc) {
                        documentsTab[index].sections.find((element, index2) => {
                            if (element.id == id_sect) {
                                Object.assign(documentsTab[index].sections[index2], {
                                    'compliances': this.compliances,
                                    'id_section': id_sect,
                                    'id_document': id_doc
                                })
                            }
                        })
                    }
                })
                this.documents = documentsTab
                this.totalItemsCompliance[dataSection.id] = data['hydra:totalItems']
            }).catch(() => {
            })
        },
        collapseAll() {
            this.expandedRowsDoc = [];
        },
        dialogEvaluation(dataComp, isInAP) {
            this.evaluationFileDeleted = false
            this.upload_file_evaluation = false
            this.evaluationDialog = false
            this.evaluationDialog = true
            delete this.evaluation.evaluation_file
            if (isInAP === 'isInAP'){
                this.idEvaluation = dataComp.id
                this.idSection = dataComp.compliance?.section?.id;
                this.id_document = dataComp.compliance?.section?.document?.id
                this.section = dataComp.compliance?.section
                this.evaluation.id = this.idEvaluation
                this.titleDialogEvaluation = this.section?.title + '\n' + dataComp.compliance?.title
                this.evaluation_date = ''

            }else{
                this.idEvaluation = dataComp.evaluations[0].id
                this.idSection = dataComp.idSection;
                this.id_document = dataComp.id_document
                this.section = dataComp.section
                this.evaluation.id = this.idEvaluation
                this.titleDialogEvaluation = dataComp.titleSection + '\n' + dataComp.title
                this.evaluation_date = ''

            }
            this.getDetailsEvaluation(this.idEvaluation);
            this.getHistoricEvaluation(this.idEvaluation);
            this.onPageActionPlan(1)

        },
        getStatusEvaluation() {
            return this.store.dispatch(Actions.GET_STATUS_EVALUATION, {'customHeader': false}).then(data => {
                this.valuesEvaluations = data
                this.valuesEvaluations = this.valuesEvaluations.filter(item => item.id === 47 || item.id === 299 || item.id === 48 || item.id === 300 || item.id === 49 || item.id === 50 || item.id === 51 || item.id === 52 || item.id === 310 || item.id === 311 || item.id === 312)
            }).catch(() => {
            })
        },
        getDetailsEvaluation(idEval) {
            this.btnEvaluationSubmit = true
            this.store.dispatch(Actions.GET_EVALUATION_BY_ID, {
                'idEval': idEval,
                'idSite': this.id_site,
                'customHeader': false
            }).then(data => {
                this.btnEvaluationSubmit = false
                this.evaluationStatus = this.valuesEvaluations.find(element =>
                    element.id == data.status.id
                )
                if (moment(data?.evaluation_date).isValid()) {
                  this.evaluation_date = new Date(moment(data.evaluation_date).format('yy-MM-DD'))

                }
                this.evaluation.annotation = data.annotation;
                this.evaluation.comment = data.comment;
                this.evaluation.id = this.idEvaluation
                this.evaluation.annotation_compliance = data.compliance.annotation_compliance
                this.evaluation.live_status = data.live_status
                this.compliance_id = data.compliance?.id
                this.evaluation_file = data.evaluation_file

            }).catch(() => {
            })
        },
        saveEvaluation() {
            this.submittedEval = true
            if (this.evaluationStatus) {
                if (this.evaluation_date === moment().format('DD/MM/yy')) {
                    this.evaluation.evaluation_date = moment().format()
                } else if (moment(this.evaluation_date).isValid()) {
                    this.evaluation.evaluation_date = moment(this.evaluation_date).format()
                }
                this.evaluation['status'] = '/api/evaluation_statuses/' + this.evaluationStatus.id
                this.evaluation.user = 'api/users/' + this.user_id
                this.btnEvaluationSubmit = true
                if (this.evaluationFileDeleted){
                  this.evaluation.evaluation_file = 'deleted'
                }

                this.store.dispatch(Actions.PUT_EVALUATION, this.evaluation).then(async () => {
                    this.toast.add({
                        severity: 'success',
                        detail: this.t('evaluationHasSuccessfullyModified'),
                        life: 3000
                    });
                      this.evaluationDialog = false
                      await this.getDocumentsEval(this.nbPageDocument, this.nbItems, this.id_document).then(() => {
                      })
                      await this.getSectionEval(this.nbpageSection, 10, this.id_document, this.idSection).then(() => {
                      })
                      this.submittedEval = false
                      this.btnEvaluationSubmit = false

                }).catch(() => {
                })
            }
        },
        UploadFileEvaluation() {

          if(this.fileEvaluation && this.upload_file_evaluation){
            this.btnEvaluationFile = true
              this.store.dispatch(Actions.UPLOAD_FILE, {
                type: 'evaluation',
                idEvaluation: this.evaluation?.id,
                fileName:this.fileEvaluation
              }).then(async (data) => {
                this.toast.add({
                  severity: 'success',
                  detail: this.t('fileHasBeenUploadedToCompliance'),
                  life: 3000
                });
                this.btnEvaluationFile = false
                this.evaluation_file = data
                this.upload_file_evaluation = false
              }).catch(() => {
                this.toast.add({
                  severity: 'error',
                  detail: this.t('anErrorOccurredWhileUploadingFileToCompliance'),
                  life: 3000
                });
              });
            }else{
                this.toast.add({
                  severity: 'success',
                  detail: this.t('fileRequired'),
                  life: 3000
                });
          }
        },
        onPageDoc(event) {
            this.nbItems = event.rows
            this.nbPageDocument = event.page + 1
            this.getDocuments(event.page + 1, this.nbItems);
        },
        onPageSec(documentId, page) {
            this.nbpageSection = page
            this.onRowExpandDoc(documentId, page);
        },
        onpageCompliance(dataSection, event) {
            this.nbPageCompliance = event.page + 1
            this.onRowExpandSection(dataSection, this.nbPageCompliance);
        },
        exportDocuments() {
            let docSelected = []
            let user = '/api/users/' + this.user.id
            if (this.selectedDocuments) {
                this.selectedDocuments.forEach(element => {
                    docSelected.push(element.id)
                })
            }
            let idTheme = []
            let idDomain = []
            let idSubDomain = []
            if (this.categoryValueTheme?.length > 0) {
                for (let i = 0; i < this.categoryValueTheme.length; i++) {
                    idTheme.push(this.categoryValueTheme[i].id)

                }
            }
            if (this.categoryValueDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueDomain.length; i++) {
                    idDomain.push(this.categoryValueDomain[i].id)
                }
            }
            if (this.categoryValueSubDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueSubDomain.length; i++) {
                    idSubDomain.push(this.categoryValueSubDomain[i].id)
                }
            }

            this.store.dispatch(Actions.EXPORTS_POST, {
                user: user, model: "exigenceExport",
                params: {
                    docs_id: docSelected,
                    site_id: this.id_site,
                    theme_id: idTheme,
                    domain_id: idDomain,
                    sub_domain_id: idSubDomain,
                    text_content: this.textAdmin,
                    'rate_progress': this.rateProgressEvalFilter,
                    'rate_conformity': this.rateConformityEvalFilter,
                },
                site_id: parseInt(this.id_site),
            }).then(() => {
                this.toast.add({
                    severity: 'success',
                    detail: this.t('exportWasSuccessfullySent'),
                    life: 8000
                });
            })
        },
        getCategoriesByTheme() {
            let tabCategoriesTheme = []
            let tabCategoriesDomain = []
            let tabCategoriesSubDomain = []
            let tabCategoriesDomainMenu = []
            let tabCategoriesSubDomainMenu = []
            return this.store.dispatch(Actions.GET_CATEGORIES_TREE_FILTER, {
                site: this.id_site,
                'customHeader': false,
            }).then(data => {
                var formatSelectTree = (obj) => {
                    obj.forEach((element) => {
                        if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
                            formatSelectTree(element.categories)
                            if (element?.type === 'THEME') {
                                tabCategoriesTheme.push(element)
                            } else if (element?.type === 'DOMAIN') {
                                let newObjDomain = { ...element };
                                tabCategoriesDomain.push(element)
                              newObjDomain.name = element.name + ' ( ' + element.parent_name + ') '
                              tabCategoriesDomainMenu.push(newObjDomain)
                            }
                        } else {
                            if (element?.type === 'SUB_DOMAIN') {
                               let newObjSubDomain = { ...element };
                              newObjSubDomain.name = element.name + ' ( ' + element.parent_name + ') '
                              tabCategoriesSubDomainMenu.push(newObjSubDomain)
                              tabCategoriesSubDomain.push(element)
                            }
                        }
                        Object.assign(element, {"children": element.categories})
                        Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
                        Object.assign(element, {"label": element.name})
                        Object.assign(element, {"key": element.id})
                        Object.assign(element, {
                            "data": {
                                "id": element.id,
                                "name": element.name,
                                "type": element.type,
                                "code": element.code,
                            }
                        })
                    });
                    return obj;
                };

              this.tabCategories = formatSelectTree(data)
              tabCategoriesTheme.sort((a, b) => a.name.localeCompare(b.name));
              tabCategoriesDomainMenu.sort((a, b) => a.name.localeCompare(b.name));
              tabCategoriesSubDomainMenu.sort((a, b) => a.name.localeCompare(b.name));

                this.categoriesTheme = tabCategoriesTheme;
                this.categoriesDomain = tabCategoriesDomainMenu;
                this.categoriesSubDomain = tabCategoriesSubDomainMenu;

                this.tabCategoriesTheme = tabCategoriesTheme
                this.tabCategoriesDomain = tabCategoriesDomain
                this.tabCategoriesSubDomain = tabCategoriesSubDomain

                this.categoriesThemeConst = tabCategoriesTheme
                this.categoriesDomainConst = tabCategoriesDomain
                this.categoriesSubDomainConst = tabCategoriesSubDomain
            })
        },
        filterDataTable(event) {
            this.textAdmin = event?.filters?.titleDescription?.value
            this.categoryValueTheme = event?.filters?.theme?.value
            this.rateProgressEvalFilter = event?.filters?.rate_progress?.value
            this.rateConformityEvalFilter = event?.filters?.rate_conformity?.value
            if (!event?.filters?.theme?.value && !event?.filters?.domain?.value && !event?.filters?.subDomain?.value) {
                this.categoriesTheme = this.tabCategoriesTheme
                this.categoriesDomain = this.tabCategoriesDomain
                this.categoriesSubDomain = this.tabCategoriesSubDomain
            }
            if (event?.filters?.theme?.value) {
                this.changeTheme(event?.filters?.domain?.value)
            } else if (!event?.filters?.domain?.value) {
                this.getCategoriesByTheme()
            }
            if (this.categoriesDomain.length > 0) {
                if (event?.filters?.domain?.value !== this.categoryValueDomain) {
                    this.categoryValueDomain = event.filters.domain.value
                    this.changeDomain()
                    if ((this.categoryValueSubDomain === 2 && !event?.filters?.subDomain?.value) || (this.categoryValueSubDomain === 2 && event?.filters?.subDomain?.value)) {
                        event.filters.subDomain.value = null
                    }
                }
                this.categoryValueSubDomain = event?.filters?.subDomain?.value
            } else {
                event.filters.domain.value = null
                event.filters.subDomain.value = null
                this.categoryValueDomain = ''
                this.categoryValueSubDomain = null
            }
            this.getDocuments(this.nbPageDocument, this.nbItems)
        },
        changeTheme(domain) {
            let tabCategoriesDomain = []
            let tabCategoriesSubDomain = []
            this.categoryValueTheme.forEach(element => {
                let objTheme = this.categoriesTheme.find(o => o.id == element.id);
                if (objTheme) {
                    objTheme.categories?.forEach(function (element) {
                        if (element.type === 'DOMAIN') {
                          let objDomain = {... element}
                          objDomain.name += ' ( ' + objDomain.parent_name + ' ) '
                            tabCategoriesDomain?.push(objDomain)
                            if (element.categories?.length > 0 && !domain) {
                                element.categories?.forEach(elemSubDomain => {
                                  let objSubDomain = {... elemSubDomain}
                                  objSubDomain.name += ' ( ' + objSubDomain.parent_name + ' ) '
                                    tabCategoriesSubDomain?.push(objSubDomain)
                                })
                            }
                        }
                    })

                }
            })
          if (domain){
            domain.forEach(element =>{
              if (element.type === 'DOMAIN') {
                if (element.categories?.length > 0 ) {
                  element.categories?.forEach(elemSubDomain => {
                    let objSubDomain = {... elemSubDomain}
                    objSubDomain.name += ' ( ' + objSubDomain.parent_name + ' ) '
                    tabCategoriesSubDomain?.push(objSubDomain)
                  })
                }

              }
            })

          }

          tabCategoriesDomain.sort((a, b) => a.name.localeCompare(b.name));
          tabCategoriesSubDomain.sort((a, b) => a.name.localeCompare(b.name));

            this.categoriesDomain = tabCategoriesDomain;
            this.categoriesSubDomain = tabCategoriesSubDomain;
        },
        changeDomain() {
            let tabCategoriesSubDomain = []
            if (this.categoryValueDomain) {

                this.categoryValueDomain.forEach(element => {
                    let objDomain = this.categoriesDomain.find(o => o.id == element.id);
                    objDomain.categories.forEach(function (element) {
                        if (element.type === 'SUB_DOMAIN') {
                          let objSubDomain = {... element}
                          objSubDomain.name += ' ( ' + objSubDomain.parent_name + ' ) '
                            tabCategoriesSubDomain.push(objSubDomain)
                        }
                    })
                })

              tabCategoriesSubDomain.sort((a, b) => a.name.localeCompare(b.name));

                this.categoryValueSubDomain = 2
                this.categoriesSubDomain = tabCategoriesSubDomain;
            }
        },
        hideDialog() {
            this.evaluationDialog = false
        },
        rowClass(rowData) {
            return rowData.compliances_count > 0 ? "" : "no-expander";
        },

        rowClassDocument(rowData) {
            return rowData.compliances_count > 0 ? "" : "no-expander";
        },
        truncate(source) {
            if (source) {
                let size
                if (screen.width <= 768) {
                    size = 100
                    return source.length > size ? source.slice(0, size - 1) + " …" : source;
                } else {
                    size = 125
                    return source.length > size ? source.slice(0, size - 1) + " …" : source;
                }
            }
        },
        truncateText(source, size) {
            if (source) {
                return source.length > size ? source.slice(0, size - 1) + " …" : source;
            }
        },
        verifPiNa(NA, PI, nbCompliance) {
            return parseInt(NA) + parseInt(PI) === nbCompliance
        },
        formatDate(value) {
            if (value && value !== undefined) {
                return new Date(value).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            } else return ' '
        },
        getDocumentsEval(page = 1, nbItems, idDocument) {
            this.loadingDoc = true;
            let idTheme = []
            let idDomain = []
            let idSubDomain = []
            if (this.categoryValueTheme?.length > 0 && !this.categoryValueSubDomain) {
                for (let i = 0; i < this.categoryValueTheme.length; i++) {
                    idTheme.push(this.categoryValueTheme[i].id)
                }
            }
            if (this.categoryValueDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueDomain.length; i++) {
                    idDomain.push(this.categoryValueDomain[i].id)
                }
            }
            if (this.categoryValueSubDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueSubDomain.length; i++) {
                    idSubDomain.push(this.categoryValueSubDomain[i].id)
                }
            }
            return this.store.dispatch(Actions.GET_DOCUMENTS_STAT, {
                'idSite': this.id_site,
                'page': page,
                'limit': nbItems,
                'customHeader': true,
                'theme': idTheme.length > 0 ? idTheme : '',
                'domain': idDomain.length > 0 ? idDomain : '',
                'sub_domain': idSubDomain.length > 0 ? idSubDomain : '',
            }).then(async data => {
                if (data) {
                    data['hydra:member'][0].forEach(element => {
                        let objTheme = this.categoriesTheme?.find(o => o.id == element.theme_id);
                        let objDomain = this.categoriesDomain?.find(o => o.id == element.domain_id);
                        let objSubDomain = this.categoriesSubDomain?.find(o => o.id == element.sub_domain_id);
                        if (objTheme) {
                            element['theme_id'] = objTheme?.name
                        } else {
                            element['theme_id'] = objTheme
                        }
                        if (objDomain) {
                            element['domain_id'] = objDomain?.name
                        } else {
                            element['domain_id'] = objDomain
                        }
                        if (objSubDomain) {
                            element['sub_domain_id'] = objSubDomain?.name
                        } else {
                            element['sub_domain_id'] = objSubDomain
                        }
                    })
                    this.totalItemsDoc = data['hydra:member'][1]
                    let objDocument = data['hydra:member'][0]?.filter(item => item.id == idDocument)
                    objDocument.id_document = idDocument;
                    this.obj_document = objDocument
                    this.documents?.find((item, index) => {
                        if (objDocument[0]?.id == item.id) {
                            this.documents[index] = objDocument[0]
                            this.onPageSec(objDocument[0]?.id, this.nbpageSection)
                        }
                    })
                    await this.getRecapEvalStat()
                }
                return data;
            }).catch(() => {
            })
        },
        getSectionEval(page = 1, nbItems, idDocument, idSection) {
            return this.store.dispatch(Actions.GET_SECTIONS_STAT, {
                'document': idDocument,
                'site': this.id_site,
                'withStat': 1,
                'page': page,
                'customHeader': true,
                'limit': 10,
            }).then(data => {
                if (data) {
                    let objSection = data['hydra:member'][0].filter(item => item.id === idSection)
                    let objDocument = this.documents.filter(item => item.id === idDocument)
                    try {
                        let findDoc = this.documents.find((d) => d.id == this.id_document);
                        if (findDoc && typeof findDoc['sections'] !== "undefined") {
                            this.expandedRowsSec = findDoc.sections.filter((s) => s.id == this.idSection);
                        }
                    } catch (e) {
                        console.log(e)
                    }
                    if (this.documents) {
                        this.documents.find((item, index) => {
                            if (objDocument && objDocument[0]?.id == item.id) {
                                if (typeof this.documents[index] !== "undefined") {
                                    this.documents[index].sections.find((item2) => {
                                        if (objSection && typeof objSection[0] != "undefined" && objSection[0].id == item2.id) {
                                            objSection[0].id_document = idDocument
                                            Object.assign(this.documents[index], {
                                                'sections': this.sections,
                                                'id_document': this.documents[index].id
                                            })
                                            this.onRowExpandSection(objSection[0], this.nbPageCompliance).then(() => {
                                                this.loadingDoc = false;
                                            })
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
                return data;
            }).catch(() => {
            })
        },
        getHistoricEvaluation(idEval) {
            this.store.dispatch(Actions.HISTORIC_EVALUATION, {
                idEvaluation: idEval,
                'page': this.pageHistEval,
                'customHeader': true,
                'limit': 10,
            }).then(data => {
                if (data) {
                    this.actual_user_name = data['hydra:member'][0].actual_user_eval
                    this.actual_site_name = data['hydra:member'][0].actual_status_eval
                    let actual_comment = data['hydra:member'][0].comment_eval
                    let actual_evaluation_date = data['hydra:member'][0].date_evaluation
                    this.historicEvaluation = data['hydra:member'][1]
                    this.historicEvaluation.unshift({username: this.actual_user_name, status: this.actual_site_name, comment: actual_comment, evaluation_date: actual_evaluation_date})
                    this.totalItemsHistEval = data['hydra:totalItems'][1] + 1
                }
            })
        },
        onPageHistEval(nbPage) {
            this.nbPageHistoric = nbPage
            this.getHistoricEvaluation(nbPage, this.idEvaluation)
        },
        getRecapEvalStat() {
            // this.titleGlobalDocument = ''
            let idTheme = []
            let idDomain = []
            let idSubDomain = []
            if (this.categoryValueTheme?.length > 0 && !this.categoryValueSubDomain) {
                for (let i = 0; i < this.categoryValueTheme.length; i++) {
                    idTheme.push(this.categoryValueTheme[i].id)

                }
            }
            if (this.categoryValueDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueDomain.length; i++) {
                    idDomain.push(this.categoryValueDomain[i].id)
                }
            }
            if (this.categoryValueSubDomain?.length > 0) {
                for (let i = 0; i < this.categoryValueSubDomain.length; i++) {
                    idSubDomain.push(this.categoryValueSubDomain[i].id)
                }
            }
          return this.store.dispatch(Actions.DOCUMENTS_RECAP_EVAL_STAT, {
                entity: this.id_site,
                'customHeader': false,
                'limit': this.nbItems,
                'theme': idTheme.length > 0 ? idTheme : '',
                'domain': idDomain.length > 0 ? idDomain : '',
                'sub_domain': idSubDomain.length > 0 ? idSubDomain : '',
                'term': this.textAdmin,
                'rate_progress': this.rateProgressEvalFilter,
                'rate_conformity': this.rateConformityEvalFilter,
            }).then(data => {
                if (this.totalItemsDoc >= 2) {
                    this.titleGlobalDocument = this.totalItemsDoc + ' textes'
                } else if (this.totalItemsDoc == 1) {
                    this.titleGlobalDocument = this.totalItemsDoc + ' texte'
                } else {
                    this.titleGlobalDocument = ''
                }

                if (data.result.length > 0) {
                    data?.result?.forEach(element => {
                        if (element.status_id == 53) {
                            this.totalComplianceEvaluer = element.compliances_count
                        } else if (element?.status_id == 313) {
                            this.totalComplianceReevaluer = element.compliances_count
                        }
                    })

                    if (this.totalComplianceEvaluer > 0 && this.totalComplianceReevaluer > 0) {
                        if (this.totalComplianceEvaluer >= 2) {
                            this.titleGlobalDocument += ' (' + this.totalComplianceEvaluer + this.t('requirementsEvaluated')
                        } else if (this.totalComplianceEvaluer == 1) {
                            this.titleGlobalDocument += ' (' + this.totalComplianceEvaluer + this.t('requirementEvaluated')
                        }
                    } else if (this.totalComplianceEvaluer > 0) {
                        if (this.totalComplianceEvaluer >= 2) {
                            this.titleGlobalDocument += ' (' + this.totalComplianceEvaluer + this.t('requirementsEvaluated') + ')'
                        } else if (this.totalComplianceEvaluer == 1) {
                            this.titleGlobalDocument += ' (' + this.totalComplianceEvaluer + this.t('requirementEvaluated') + ')'
                        }
                    }
                    if (this.totalComplianceReevaluer > 0 && this.totalComplianceEvaluer > 0) {
                        if (this.totalComplianceReevaluer >= 2) {
                            this.titleGlobalDocument += ' / ' + this.totalComplianceReevaluer + this.t('requirementsReEvaluated') + ')'
                        } else {
                            this.titleGlobalDocument += ' / ' + this.totalComplianceReevaluer + this.t('requirementReEvaluated') + ')'
                        }
                    } else if (this.totalComplianceReevaluer > 0) {
                        if (this.totalComplianceReevaluer >= 2) {
                            this.titleGlobalDocument += ' (' + this.totalComplianceReevaluer + this.t('requirementsReEvaluated') + ')'
                        } else {
                            this.titleGlobalDocument += ' (' + this.totalComplianceReevaluer + this.t('requirementReEvaluated') + ')'
                        }
                    }
                }
            })
        },
        chnageStatus() {
            this.evaluation_date = moment().format('DD/MM/yy')
        },
        importdocuments() {
            this.importDocumentDialog = true
        },
        onUploadDocuments() {
            this.fileImportDocuments = this.$refs.file.files[0]
        },
        importDocument() {
            this.store.dispatch(Actions.IMPORT_FILE, {
                file: this.fileImportDocuments,
                model: 'importEvaluation',
                params: {site_id: this.id_site,},
                site_id: this.id_site,
            }).then(() => {
                this.toast.add({
                    severity: 'success',
                    detail: this.t('importWasSuccessfullySent'),
                    life: 8000
                });
                this.importDocumentDialog = false
                this.getStatusImport()
                this.loadingDoc = true
            })
        },
        hideDialogImportSites() {
            this.importDocumentDialog = false
        },
        getStatusImport() {
            return this.store.dispatch(Actions.GET_STATUS_IMPORT, {
                site_id: this.id_site,
                'customHeader': true,
                'model': 'App\\Command\\EvaluationImportCommand'
            }).then(() => {
            })
        },
        getActionPlans(page) {
            this.loadingAPList = true
            this.store.dispatch(Actions.GET_ACTION_PLAN, {
                page: page,
                'customHeader': true,
                'evaluation': this.idEvaluation
            }).then(data => {
                if (data) {
                    this.loadingAPList = false
                    this.actionPlanList = data['hydra:member']
                    this.totalItemsAP = data['hydra:totalItems']
                }
            })
        },
        onPageActionPlan(page) {
            this.nbPageActionPlan = page
            this.getActionPlans(page);
        },
        openModalAP() {
            this.PADialog = true
            this.planAction = {}
            this.valueSubDomain = ''
            this.priority = ''
            this.start_date = ''
            this.end_date = ''
            this.revised_end_date = ''
            this.apPilot = ''
            this.responsibleAP = ''
            this.verification_date = ''
            this.typeProgress = {id: 1, value: 'Par le chargé du plan d\'actions'}
            this.rateProgress = {id: 1, value: 0, label: '0%'}
        },
        selectNode(selected) {
            if (selected.children?.length) {
                setTimeout(() => {
                    this.valueSubDomain = {[selected.id]: false}
                }, 100);
            }
        },
        hideDialogPA() {
            this.PADialog = false
            this.submittedPA = false
        },
        savePA() {
            this.submittedPA = true
            if (moment(this.start_date).isValid()) {
                this.planAction.start_date = moment(this.startDate).format()
            }
            if (moment(this.end_date).isValid()) {
                this.planAction.end_date = moment(this.end_date).format()
            }
            if (moment(this.revised_end_date).isValid()) {
                this.planAction.revised_end_date = moment(this.revised_end_date).format()
            }
            if (this.responsibleAP) {
                this.planAction.user_in_charge = 'api/users/' + this.responsibleAP.id
            }
            if (this.apPilot) {
                this.planAction.user_pilot = 'api/users/' + this.apPilot.id
            }
            this.planAction.evaluation = ['api/evaluations/' + this.idEvaluation]
            this.planAction.site = 'api/sites/' + this.id_site
            if (this.valueSubDomain) {
                this.planAction.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
            }
            if (this.priority) {
                this.planAction.priority = this.priority.value
            }
            if (this.rateProgress) {
                this.planAction.rate_progress = this.rateProgress.value
            }
            if (this.typeProgress) {
                this.planAction.type_progression = this.typeProgress.value
            }
            this.planAction.created_by = 'api/users/' + this.user_id
            if (this.planAction.title && this.planAction.start_date && this.planAction.end_date && this.planAction.revised_end_date && this.responsibleAP) {
                this.btnAPSubmit = true
                this.store.dispatch(Actions.ADD_ACTION_PLAN, this.planAction).then(() => {
                    this.toast.add({
                        severity: 'success',
                        detail: this.t('actionPlanHasSuccessfullyAdded'),
                        life: 3000
                    });
                    this.onPageActionPlan(this.nbPageActionPlan)
                    this.PADialog = false
                    this.btnAPSubmit = false
                })
            }
        },
        getUsersByCustomer() {
            this.store.dispatch(Actions.GET_USERS, {}).then(data => {
                this.listUsers = data
                this.listUsers.forEach(element => {
                    element['name'] = element.firstname + ' ' + element.lastname
                })
            })
        },
        getUsersAP() {
            this.store.dispatch(Actions.GET_USERS_AP, {site_id: this.id_site, 'customHeader': false,}).then((data) => {
                // this.listUsers = data
                this.listUsers = data.filter(item => item.hide_in_pa !== 1)

            })
        },
        modalEditPAEval(data) {
            this.PAEvalEditDialog = true
            this.planAction = {}
            this.valueSubDomain = ''
            this.priority = ''
            this.start_date = ''
            this.end_date = ''
            this.revised_end_date = ''
            this.apPilot = ''
            this.responsibleAP = ''
            this.verification_date = ''
            this.PAEditDialog = true
            this.loadingComments = true
            this.loadingTasks = true
          //  this.getActionPlan(data)
            this.idPA = data.id
            this.evaluationsAP = []
            this.getActionPlanRef(data.id)
        },
        getActionPlan(data) {
            this.titleAP = data.title
            this.planAction = data
            this.loadingTasks = true
            this.loadingComments = true
            this.actionPlanDetails = data
            this.totalItemsCompliancePA = data?.evaluation?.length
            this.totalItemsTasks = data?.action_plan_tasks?.length
            this.totalItemsComments = data?.action_plan_comments?.length
            this.loadingTasks = false
            this.loadingComments = false
            if (data.sub_domain) {
                this.valueSubDomain = {[data.sub_domain.id]: true}
            }
            this.priority = this.valuesPriorities.find(item => item.value == data.priority)
            if (moment(data.start_date).isValid()) {
              this.start_date = new Date(moment(data.start_date).format('yy-MM-DD'))
            }
            if (moment(data.end_date).isValid()) {
                this.end_date = new Date(moment(data.end_date).format('yy-MM-DD'))
            }
            if (moment(data.revised_end_date).isValid()) {
              this.revised_end_date = new Date(moment(data.revised_end_date).format('yy-MM-DD'))

            }
            if (data.user_pilot) {
                this.apPilot = this.listUsers.find(item => item.id == data.user_pilot.id)
            }
            if (data.user_in_charge) {
                this.responsibleAP = this.listUsers.find(item => item.id == data.user_in_charge.id)
            }
            if (data.type_progression) {
                this.typeProgress = this.listTypeProgression.find(item => (item.value).toUpperCase() == (data.type_progression).toUpperCase())
            } else {
                this.typeProgress = {id: 1, value: 'Par le chargé du plan d\'actions'}
            }
            if (data.rate_progress) {
                this.rateProgress = this.listRateProgression.find(item => item.value == data.rate_progress)
            } else {
                this.rateProgress = {id: 1, value: 0, label: '0%'}
            }
            if (data.verification_date !== undefined) {
                if (moment(data.verification_date).isValid()) {
                  this.verification_date = new Date(moment(data.verification_date).format('yy-MM-DD'))

                }
            }
            if (data.verifier_user) {
                this.verifier_user = this.listUsers.find(item => item.id == data.verifier_user.id)
            }
        },
        hideDialogEditPAEval() {
            this.PAEvalEditDialog = false
        },
        savePAEdit() {
            this.submittedPA = true
            if (moment(this.start_date).isValid()) {
                this.planAction.start_date = moment(this.start_date).format()
            }
            if (moment(this.end_date).isValid()) {
                this.planAction.end_date = moment(this.end_date).format()
            }
            if (moment(this.revised_end_date).isValid()) {
                this.planAction.revised_end_date = moment(this.revised_end_date).format()
            }
            if (moment(this.verification_date).isValid()) {
                this.planAction.verification_date = moment(this.verification_date).format()
            }
            if (this.responsibleAP) {
                this.planAction.user_in_charge = 'api/users/' + this.responsibleAP.id
            }
            if (this.apPilot) {
                this.planAction.user_pilot = 'api/users/' + this.apPilot.id
            }
            if (this.verifier_user) {
                this.planAction.verifier_user = 'api/users/' + this.verifier_user.id
            }
            this.planAction.site = 'api/sites/' + this.id_site
            if (this.valueSubDomain) {
                this.planAction.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
            }
            if (this.priority) {
                this.planAction.priority = this.priority.value
            }
            if (this.rateProgress) {
                this.planAction.rate_progress = this.rateProgress.value
            }
            if (this.typeProgress) {
                this.planAction.type_progression = this.typeProgress.value
            }

            if (this.evaluationsAP.length > 0) {
              this.planAction.evaluation = []
              this.evaluationsAP.forEach(element => {
                this.planAction.evaluation.push('api/evaluations/' + element.id)
              })
            }
            this.planAction.updated_by = 'api/users/' + this.user_id
            this.planAction.created_by = this.planAction.created_by ? 'api/users/' + this.planAction.created_by.id : null

            let actionPlan = this.planAction
            if (this.planAction.title && this.start_date && this.end_date && this.revised_end_date && this.responsibleAP) {
                this.btnAPSubmit = true
                this.store.dispatch(Actions.PUT_ACTION_PLAN, actionPlan).then(async () => {
                    this.toast.add({
                        severity: 'success',
                        detail: this.t('actionPlanHasSuccessfullyModified'),
                        life: 3000
                    });
                    this.PAEvalEditDialog = false
                    this.getDetailsEvaluation(this.idEvaluation);
                    this.onPageActionPlan(1)
                    await this.getDocumentsEval(this.nbPageDocument, this.nbItems, this.id_document).then(() => {
                    })
                    await this.getSectionEval(this.nbpageSection, 10, this.id_document, this.idSection).then(() => {
                    })
                    this.submittedPA = false
                    this.btnAPSubmit = false
                })
            }
        },
        modalAttachAP() {
            this.attachPADialog = true
            this.getActionPlansInit(1, 'attach')
        },
        hideDialogAttachPA() {
            this.attachPADialog = false
        },
        saveAttachPA() {
          this.disableAttachPA = true
            let selectedAPAttach = []
            this.selectedAPAttach.forEach(element => {
                selectedAPAttach.push('api/action_plans/' + element.id)
            })
            this.store.dispatch(Actions.PUT_EVALUATION, {
                id: this.idEvaluation,
                action_plans: selectedAPAttach
            }).then(() => {
                // this.getActionPlans(1)
                this.onPageActionPlan(this.nbPageActionPlan)
                this.attachPADialog = false
                this.selectedAPAttach = []
                this.toast.add({
                    severity: 'success',
                    detail: this.t('actionPlanHasSuccessfullyAdded'),
                    life: 3000
                });
              this.disableAttachPA = false
            })
        },
        getActionPlansInit(page, type) {
            this.loadingAP = true
            this.loadingAPAttach = true
            this.actionPlanListAttach = []
            this.totalItemsAPAttach = 0
            let rate_progress_start
            if (this.rateProgressFilter) {
                rate_progress_start = this.rateProgressFilter[0]
            }
            let rate_progress_end
            if (this.rateProgressFilter) {
                rate_progress_end = this.rateProgressFilter[1]
            }
            let startDate
            if (this.startDateFilter) {
                startDate = moment(this.startDateFilter).format('yy-MM-DD')
            }
            let endDate
            if (this.endDateFilter) {
                endDate = moment(this.endDateFilter).format('yy-MM-DD')
            }
            let revisedEndDate
            if (this.revisedEndDateFilter) {
                revisedEndDate = moment(this.revisedEndDateFilter).format('yy-MM-DD')
            }
            if (this.id_site !== 'null') {
                return this.store.dispatch(Actions.GET_ACTION_PLAN, {
                    page: page,
                    'customHeader': true,
                    'site': this.id_site,
                    'title': this.titleFilter,
                    'start_date': startDate,
                    'end_date': endDate,
                    'revised_end_date': revisedEndDate,
                    'user_pilot': this.userPilotFilter,
                    'user_in_charge': this.userInChargeFilter,
                    'priority': this.priorityFilter,
                    'rate_progress_start': rate_progress_start,
                    'rate_progress_end': rate_progress_end,
                }).then(data => {
                    if (data) {
                        if (type === 'all') {
                            this.actionPlanList = data['hydra:member']
                            this.totalItemsAP = data['hydra:totalItems']
                        } else if (type === 'attach') {
                            this.actionPlanListAttach = data['hydra:member']
                            this.actionPlanListAttach = this.actionPlanListAttach.filter((elem) =>
                                this.actionPlanList.every(ele => elem.id !== ele.id)
                            );
                            this.totalItemsAPAttach = data['hydra:totalItems']
                        } else {
                            this.actionPlanList = data['hydra:member']
                            this.totalItemsAP = data['hydra:totalItems']
                            this.actionPlanListAttach = data['hydra:member']
                            this.actionPlanListAttach = this.actionPlanListAttach.filter((elem) =>
                                this.actionPlanList.every(ele => elem.id !== ele.id)
                            );
                        }
                    }
                    this.loadingAP = false
                    this.loadingAPAttach = false
                })
            } else {
                this.loadingAPAttach = false
            }
        },
        filterDataTableAttach(event) {
            this.titleFilter = event?.filters?.title?.value
            this.startDateFilter = event?.filters?.start_date?.value
            this.endDateFilter = event?.filters?.end_date?.value
            this.revisedEndDateFilter = event?.filters?.revised_end_date?.value
            this.userPilotFilter = event?.filters?.user_pilot?.value
            this.userInChargeFilter = event?.filters?.user_in_charge?.value
            this.rateProgressFilter = event?.filters?.rate_progress?.value
            if (event?.filters?.priority?.value === "red") {
                this.priorityFilter = '80'
            } else if (event?.filters?.priority?.value === "green") {
                this.priorityFilter = '79'
            } else if (event?.filters?.priority?.value === "yellow") {
                this.priorityFilter = '78'
            }
            this.getActionPlansInit(1, 'attach')
        },
        onPageActionPlanAttach(page) {
            this.nbPageActionPlanAttach = page
            this.getActionPlansInit(page, 'attach');
        },
        changeEndDate(event) {
            this.revised_end_date = event
        },
        deleteAPEval(data) {
            this.$confirm.require({
                message: this.t('AreYouSureYouWantToDelete'),
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(Actions.DELETE_EVALUATION_AP, {
                        idPA: data.id,
                        idEval: this.idEvaluation
                    }).then(() => {
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('actionPlanHasSuccessfullyDeleted'),
                            life: 3000
                        });
                        this.getActionPlans(1)
                    })
                },
                reject: () => {
                },
                onHide: () => {
                }
            });
        },
        differenceDate(lastUpdateDate) {
            if (lastUpdateDate) {
                lastUpdateDate = new Date(moment(lastUpdateDate).format('yy-MM-DD')).getTime()
                let currentDate = new Date(moment().format('yy-MM-DD')).getTime()
                const msInDay = 24 * 60 * 60 * 1000;
                let nbrDays = Math.round(
                    Math.abs(currentDate - lastUpdateDate) / msInDay
                );
                if (nbrDays < 90)
                    return true
            }
            return false;
        },
        attachFile(idMedia, type) {
            this.store.dispatch(Actions.GET_MEDIA_ID, {idMedia: idMedia, 'customHeader': false}).then(media => {
                this.store.dispatch(Actions.ATTACH_FILES, {
                    idMedia: idMedia,
                    type: type,
                    'customHeader': true
                }).then((data, filename) => {
                    const url = window.URL.createObjectURL(data, filename);
                    saveAs(url, media.name)
                })
            })

        },
        openModalTransversalEvaluations(data) {
            this.selectedSiteAssignToDocument = []
            this.selectedEvaluations = []
            this.transversalEvaluationsDialog = true
            this.id_document = data.id
            this.getSitesAssignToDocument()
            this.getEvaluationsByDocument()
        },
        getSitesAssignToDocument() {
            let listSitesAssignToDocument = []
            this.loadingSiteAssignToDocument = true
            this.store.dispatch(Actions.ASSIGN_SITES_BY_DOCUMENT, {
                isApplicable: 1,
                document_id: this.id_document,
                user_id: this.user.id
            }).then((data) => {
                let indexF = 0

                function addConcatName(arr, parentCode = null, parent) {

                    arr.forEach((obj, index) => {
                        const childSiteName = obj.site_name || '';
                        if (parentCode) {
                            obj.concat_name = `${parentCode}-${childSiteName}`;
                        }
                        if (!parentCode) {
                            obj.concat_name = obj.name;
                        }
                        if (obj.childrens) {
                            addConcatName(obj.childrens, obj.concat_name, obj);
                        }
                        if (obj.entity_type === 'site') {
                            indexF++;
                            if (parent.site_name) {
                                Object.assign(arr[index], {
                                    "data": {
                                        'index': indexF,
                                        "id": obj.site_id,
                                        "site_name": obj.concat_name
                                    }
                                })
                            } else {
                                Object.assign(arr[index], {
                                    "data": {
                                        'index': indexF,
                                        "id": obj.site_id,
                                        "site_name": obj.concat_name
                                    }
                                })
                            }
                            listSitesAssignToDocument.push(obj.data)
                        }
                    });
                }

                let ob = data;
                addConcatName(ob);
                this.listSitesAssignToDocument = listSitesAssignToDocument.filter(item => item.id != this.id_site)
                this.totalItemsSitesAssignToDocument = this.listSitesAssignToDocument.length
                this.loadingSiteAssignToDocument = false
            })
        },
        duplicateEvaluation() {
            let assignSites = []
            let compliances = []
            this.selectedSiteAssignToDocument.forEach(element => {
                assignSites.push(element.id)
            })
            this.selectedEvaluations.forEach(element => {
                compliances.push(element.compliance_id)
            })
            this.store.dispatch(Actions.PUT_EVALUATION_DUPLICATE, {
                sites: assignSites,
                compliances: compliances,
                document_id: this.id_document,
                site_source: this.id_site
            }).then(() => {
                this.toast.add({
                    severity: 'success',
                    detail: this.t('successfullyUpdated'),
                    life: 3000
                });
                this.transversalEvaluationsDialog = false
            })
        },
        hideDialogDuplicateEvaluation() {
            this.transversalEvaluationsDialog = false
        },
        getEvaluationsByDocument() {
            this.loadingEvaluationDocument = true
            this.store.dispatch(Actions.EVALUATION_REPORT, {
                customHeader: true,
                document: this.id_document,
                site: this.id_site,
                // limit: 5,
                // page: this.nbpageEvaluationDocument,
                text: this.textEvaluationDocument
            }).then((data) => {
                this.loadingEvaluationDocument = false
                this.listEvaluationsByDocument = data

                this.listEvaluationsByDocument.forEach(element => {
                    element.title_section_compliance = element.title_section + '' + element.title_compliance
                });
                this.totalItemsEvaluationByDocument = data.length
            })
        },



        comparerAvecDecimales(x, y){
            const decimalesX = parseInt(x);
            const decimalesY = parseInt(y);
            if (decimalesX === decimalesY) {
                x = x.toString().split('.')[1]
                y = y.toString().split('.')[1]
                return parseInt(x )>= parseInt(y);
            } else {
                return x >= y;
            }
        },

        warningText(min_evaluation_date) {
            if (min_evaluation_date){
                let year = 3
                if (this.year_site)
                    year = this.year_site
                else if (this.year_customer)
                    year = this.year_customer

                let year_month = year - 0.4

                const currentDate = new Date();
                const diffInMilliseconds = currentDate.getTime() - new Date(Date.parse(min_evaluation_date)).getTime();
                let diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
                const remainingMilliseconds = diffInMilliseconds % (1000 * 60 * 60 * 24 * 365.25);
                const diffInMonths = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24 * 30.44));

                if (!isNaN(diffInYears) && !isNaN(diffInMonths)) {
                    diffInYears = Math.floor(diffInYears) + '.' + Math.floor(diffInMonths)
                    diffInYears = parseFloat(diffInYears)
                    if (this.comparerAvecDecimales(diffInYears, year_month) && diffInYears < year) {
                        return 'orange'
                    } else
                        if (year && parseFloat(diffInYears) >= year) {
                        return 'red'
                    }
                } else
                    return false
            }
            else {
                return false
            }

        },
        getUserSiteRole() {
            this.store.dispatch(Actions.GET_ROLE_USER_SITE, {
                user_id: this.user_id,
                site_id: this.id_site
            }).then((data) => {
                this.role_user_site = data ? data.user_role : data
            })
        },
        openModalTasks(data) {
            this.tasksDialog = true
            this.task = {}
            this.start_date_task = ''
            this.end_date_task = ''
            this.rateProgress_task = {id: 1, value: 0, label: '0%',}
            this.responsibleTask = ''
            if (data) {
                this.getTaskDetails(data)
            }
        },
        hideDialogTask() {
            this.tasksDialog = false
        },
        saveTask() {
            this.submittedTasks = true
            if (this.start_date_task) {
                if (moment(this.start_date_task).isValid())
                    this.task.start_date = moment(this.start_date_task).format()
            }
            if (this.end_date_task) {
                if (moment(this.end_date_task).isValid())
                    this.task.end_date = moment(this.end_date_task).format()
            }
            if (this.responsibleTask) {
                this.task.user_in_charge = 'api/users/' + this.responsibleTask.id
            }
            if (this.rateProgress_task) {
                this.task.rate_progress = this.rateProgress_task.value
            }
            this.task.action_plan = 'api/action_plans/' + this.idPA
            if (this.task.title && this.start_date_task && this.end_date_task) {
                if (this.task.id) {
                    if (this.task.created_by) {
                        this.task.created_by = 'api/users/' + this.task.created_by.id
                    }
                    this.task.updated_by = 'api/users/' + this.user_id
                    this.store.dispatch(Actions.PUT_TASKS_AP, this.task).then(() => {
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('taskHasSuccessfullyModified'),
                            life: 3000
                        });
                        this.submittedTasks = false
                        this.tasksDialog = false
                        this.getActionPlanRef(this.idPA)
                    })
                } else {
                    this.task.created_by = 'api/users/' + this.user_id
                    this.store.dispatch(Actions.ADD_TASK_AP, this.task).then(() => {
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('taskHasSuccessfullyAdded'),
                            life: 3000
                        });
                        this.submittedTasks = false
                        this.tasksDialog = false
                        this.getActionPlanRef(this.idPA)
                    })
                }
            }
        },
        getTaskDetails(data) {
            this.task = data
            if (data.start_date) {
                this.start_date_task = moment(data.start_date).format('DD/MM/yy')
            }
            if (data.end_date) {
                this.end_date_task = moment(data.end_date).format('DD/MM/yy')
            }
            if (data.user_in_charge) {
                this.responsibleTask = this.listUsers.find(item => item.id == data.user_in_charge.id)
            }
            if (data.rate_progress) {
                this.rateProgress_task = this.listRateProgression.find(item => item.value == data.rate_progress)
            } else {
                this.rateProgress_task = {id: 1, value: 0, label: '0%',}
            }
        },
        deleteTask(task) {
            this.$confirm.require({
                message: this.t('AreYouSureYouWantToDelete'),
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(Actions.DELETE_AP_TASK, task.id).then(() => {
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('taskAPHasSuccessfullyDeleted'),
                            life: 3000
                        });
                        this.getActionPlanRef(this.idPA)
                    })
                },
                reject: () => {
                },
                onHide: () => {
                }
            });
        },
        getActionPlanRef(id) {
            this.loadingTasks = true
            this.loadingComments = true
            this.loadingCompliancesPA = true
            this.store.dispatch(Actions.GET_ACTION_PLAN_BY_ID, {'customHeader': false, idAP: id}).then(data => {
                this.getActionPlan(data)
                this.planAction.action_plan_tasks = data.action_plan_tasks
                this.planAction.action_plan_comments = data.action_plan_comments
                this.evaluationsAP = data.evaluation
                this.totalItemsCompliancePA = data.evaluation.length
                this.totalItemsTasks = data.action_plan_tasks.length
                this.totalItemsComments = data.action_plan_comments.length
                this.loadingTasks = false
                this.loadingComments = false
                this.loadingCompliancesPA = false
            })
        },
        openModalComments(data) {
            this.commentAP = {}
            this.commentsDialog = true
            if (data) {
                this.commentAP.id = data.id
                this.commentAP.comment = data.comment
            }
        },
        hideDialogComment() {
            this.commentsDialog = false
        },
        saveComment() {
            this.submittedComments = true
            this.commentAP.updated_by = '/api/users/' + this.user.id
            this.commentAP.action_plan = 'api/action_plans/' + this.idPA
            if (this.commentAP.id) {
                this.store.dispatch(Actions.PUT_COMMENT_AP, this.commentAP).then(() => {
                    this.commentsDialog = false
                    this.toast.add({
                        severity: 'success',
                        detail: this.t('commentHasSuccessfullyModfied'),
                        life: 3000
                    });
                    this.getActionPlanRef(this.idPA)
                    this.submittedComments = false
                })
            } else {
                this.commentAP.created_by = '/api/users/' + this.user.id
                if (this.commentAP.comment) {
                    this.store.dispatch(Actions.ADD_COMMENT_AP, this.commentAP).then(() => {
                        this.commentsDialog = false
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('commentHasSuccessfullyAdded'),
                            life: 3000
                        });
                        this.getActionPlanRef(this.idPA)
                        this.submittedComments = false
                    })
                }
            }
        },
        deleteComment(comment) {
            this.$confirm.require({
                message: this.t('AreYouSureYouWantToDelete'),
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(Actions.DELETE_AP_COMMENT, comment.id).then(() => {
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('commentAPHasSuccessfullyDeleted'),
                            life: 3000
                        });
                        this.getActionPlanRef(this.idPA)
                    })
                },
                reject: () => {
                },
                onHide: () => {
                }
            });
        },
        hideDialogCompliance() {
            this.complianceDialog = false
        },
        saveCompliances() {
            this.planAction.id = this.idPA
            let compliancesSelected = []
            Object.entries(this.selectedCompliances).forEach(element => {
                element[1].forEach(item => {
                    compliancesSelected.push('api/evaluations/' + item.evaluations[0].id)
                })
            })
           // let objectPA = this.actionPlanList.find(item => item.id == this.idPA)
            this.evaluationsAP.forEach(element => {
                compliancesSelected.push('api/evaluations/' + element.id)
            })
            this.planAction.updated_by = 'api/users/' + this.user_id
            this.btnAPSubmit = true
            this.store.dispatch(Actions.PUT_ACTION_PLAN, {id: this.idPA, evaluation: compliancesSelected}).then(() => {
                this.selectedCompliances = []
                this.complianceDialog = false
                this.getActionPlanRef(this.idPA)
                this.btnAPSubmit = false
            })
        },
        filterDataTableCompliance(event) {
            this.textAdmin = event?.filters?.titleDescription?.value
            this.categoryValueTheme = event?.filters?.theme?.value
            if (!event?.filters?.theme?.value && !event?.filters?.domain?.value && !event?.filters?.subDomain?.value) {
                this.categoriesTheme = this.tabCategoriesTheme
                this.categoriesDomain = this.tabCategoriesDomain
                this.categoriesSubDomain = this.tabCategoriesSubDomain
            }
            if (event?.filters?.theme?.value) {

                this.changeTheme(event?.filters?.domain?.value)
            } else if (!event?.filters?.domain?.value) {
                this.getCategoriesByTheme()
            }
            if (this.categoriesDomain.length > 0) {
                if (event?.filters?.domain?.value !== this.categoryValueDomain) {
                    this.categoryValueDomain = event.filters.domain.value
                    this.changeDomain()
                    if ((this.categoryValueSubDomain === 2 && !event?.filters?.subDomain?.value) || (this.categoryValueSubDomain === 2 && event?.filters?.subDomain?.value)) {
                        event.filters.subDomain.value = null
                    }
                }
                this.categoryValueSubDomain = event?.filters?.subDomain?.value
            } else {
                event.filters.domain.value = null
                event.filters.subDomain.value = null
                this.categoryValueDomain = ''
                this.categoryValueSubDomain = null
            }
            this.getDocuments(1, this.nbItems)

        },
        openModalCompliance() {
            this.complianceDialog = true
            this.getDocuments(1, this.nbItems)
        },
        deleteCompliance(data) {
            this.$confirm.require({
                message: this.t('AreYouSureYouWantToDelete'),
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(Actions.DELETE_EVALUATION_AP, {idPA: this.idPA, idEval: data.id}).then(() => {
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('ComplianceHasSuccessfullyDeleted'),
                            life: 3000
                        });
                        this.getActionPlanRef(this.idPA)
                    })
                },
                reject: () => {
                },
                onHide: () => {
                }
            });
        },
      replaceAllColorsWithWhite(html) {
        if (!html) return '';
        return html.replace(/color: rgb\(\d{1,3}, \d{1,3}, \d{1,3}\);/g, 'color: white;');
      },
      onUploadFileEvaluation(){
        this.fileEvaluation = this.$refs.fileEvaluation.files[0]
        this.upload_file_evaluation = true
      },

      deleteFile() {
        this.$confirm.require({
          message: this.t('AreYouSureYouWantToDelete'),
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.evaluation_file = null
            this.fileEvaluation = null
            this.evaluationFileDeleted = true
          },
          reject: () => {
          },
          onHide: () => {
          }
        });
      },

    }
}
</script>
<style scoped lang="scss">
@import '../../../assets/demo/styles/badges';

::v-deep(.p-progressbar) {
  height: .5rem;
  //background-color: green;

  //.p-progressbar-value {
  //  background-color: green;
  //}
}

//.p-datatable .p-datatable-tbody > tr.no-expander > td.p-row-toggler.p-link  {
//  display: none;
//}
//.p-link{
//  display: none;
//}

.primary-btn{
  display: inline-block;
  width: fit-content;
  background: #004398;
  border-color: #004398;
  &:hover{
    background: transparent;
    color: #004398;
  }
}

</style>

